import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  client: [],
};

export const AppCheckin = createSlice({
  name: "AppCheckin",
  initialState,
  reducers: {
    add_checkin: (state, action) => {
      state.client.push(action.payload);
    },
    remove_checkin: (state) => {
      state.client = {};
    },
  },
});

export const { add_checkin, remove_checkin } = AppCheckin.actions;

export const selectChechin = (state) => state.AppCheckin.client;

export default AppCheckin.reducer;
