import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Select,
  Button,
  Input,
  Spin,
  message,
  Row,
  Col,
  Segmented,
  Empty,
  Avatar,
  Badge,
  notification,
  Popconfirm,
  Menu,
  Dropdown,
  Divider,
} from "antd";
import {
  SendOutlined,
  DeleteColumnOutlined,
  CloseCircleTwoTone,
  CloseOutlined,
} from "@ant-design/icons";
import { RiHospitalFill } from "react-icons/ri";
import PageTitleHook from "../pagetitle";
import debounce from "lodash/debounce";
import { header, uri, uri_img } from "../../assets/utils/http-request";
import logo from "../../assets/images/claima.png";
import CurrencyFormat from "react-currency-format";

import "./claims.css";
import { useAuth } from "../../assets/utils/useAuth";
import config from "../../assets/utils/config";
import Helpers, { renameFile, uuid } from "../../assets/utils/Helpers";
import { selectclient } from "../../stores/verify";
import { IoDocumentAttach } from "react-icons/io5";
import { myRequestObj } from "../../assets/utils/lib";

const { TextArea } = Input;
let fileses = {};
let uploadData = { filesHandles: {} };
const maxSize = 2;
const allowableFiles = [
  "image/jpeg",
  "application/pdf",
  "image/png",
  "image/gif",
];

const NullSafe = (data) => {
  return data.map((c) => {
    const this_dat = {};
    Object.entries(c).forEach((col) => {
      this_dat[col[0]] = col[1] || "";
    });
    return this_dat;
  });
};

const ClaimsRequestHook = (props) => {
  const [items, setItems] = useState([]);
  const [client, setClient] = useState({});
  const [complaint, setCompaint] = useState("");
  const [, setCategories] = useState([]);
  const [diagenosis, setDiagnosis] = useState([]);
  const [diagData, setDiagData] = useState([]);
  const [provDiagenosis, setProvDiagenosis] = useState([]);
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setUploading] = useState(false);
  const [, setLoadingDiagnosis] = useState(false);
  const uploadNotificationKey = "upload";

  const { user } = useAuth();
  const navigator = useNavigate();
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (!params.get("checkin") || !params.get("checkin")) navigator("/checkins");
    myRequestObj(`constableCategoriesList`).post()
      .then((res) => res?.data)
      .then((data) => {
        const dat = data.data.map((c) => {
          const this_dat = {};
          Object.entries(c).forEach((col) => {
            this_dat[col[0]] = col[1] || "";
          });
          return this_dat;
        });
        setCategories(dat);
      })
      .catch((e) =>
        notification.error({
          message: e?.msg || e?.message,
        }),
      );
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchPAData(params.get("checkin")).then((res) => {
      setItems(res.items);
      setClient(res.misc);
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  async function fetchDiagnosisList(username) {
    return fetch(`${uri}?diagnosisList`, {
      method: "POST",
      body: JSON.stringify({ search: username ?? "" }),
      headers: {
        ...header,
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => response.json())
      .then((body) => {
        setDiagnosis(body.data);
        return body.data.map((user) => ({
          label: `${user.code} ${user.name}`,
          value: user.id,
        }));
      })
      .catch((e) => {
        notification.error({
          message: e?.msg || e?.message,
        });
      })
      .finally(setLoadingDiagnosis(false));
  }

  const upload = async () => {
    if (!Object.keys(fileses).length) {
      return;
    }

    const uploadDataWithFiles = new FormData();

    Object.entries(fileses).forEach(([i, j]) => {
      j.forEach((k, l) => {
        uploadDataWithFiles.append(`files[]`, k);
      });
    });

    uploadDataWithFiles.append("item", JSON.stringify(uploadData));

    notification.info({
      message: (
        <span>
          Uploding Claim Document(s) <br></br>
          <strong>Please Do Not Refresh This Page</strong>
        </span>
      ),
      key: uploadNotificationKey,
      duration: null,
      icon: <Spin size="mediaum" />,
    });
    setUploading(true);
    let ret = 10;
    return fetch(`${uri}?saveItemDoc`, {
      method: "POST",
      body: uploadDataWithFiles,
      headers: {
        ...header,
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((res) => res.json())
      .then((body) => {
        if (body.error) {
          notification.error({
            message: body.message,
            key: uploadNotificationKey,
          });
        } else {
          notification.success({
            message: body.message,
            key: uploadNotificationKey,
          });
        }
        ret = body.error;
      })
      .catch((e) => {
        notification.error({
          message: e?.err || e?.message,
          key: uploadNotificationKey,
        });
      })
      .finally(() => {
        setUploading(false);
        notification.close(uploadNotificationKey);
        return ret;
      });
  };

  async function fetchPAData(claimsId) {
    return myRequestObj(`paList`).post({ checkinId: claimsId })
      .then((response) => response?.data)
      .then((body) => {
        const provList = body?.data?.diagnosis?.map((d) => ({
          label: `${d.code} ${d.name}`,
          value: d.id,
        }));
        setProvDiagenosis(provList);
        return body?.data;
      });
  }

  const addDiagnosis = (e) => {
    const nn = e.map((i) => {
      return diagenosis.find((j) => j.id === i.value);
    });
    setDiagData(nn);
    setValue(e);
  };

  const send_claim = async () => {
    setLoading(true);
    if (await upload()) {
      setLoading(false);
      return;
    }
    const key = "key";
    message.info({ content: "Sending PA Data", key: key, duration: 3 });
    const claimData = {
      complaint: complaint,
      diagnosis: diagData,
      checkinId: params.get("checkin"),
    };
    fetch(`${uri}?claimLog`, {
      method: "POST",
      body: JSON.stringify(claimData),
      headers: {
        ...header,
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => response.json())
      .then((body) => {
        if (body.error) {
          message.error({ content: body.message, key: key, duration: 2 });
        } else {
          message.success({ content: body.message, key: key, duration: 3 });
          setValue([]);
          navigator("/claims");
        }
      }).catch(e => {
        console.log('Message: ', e?.message || e?.msg)
      });
    setLoading(false);
  };

  return (
    <>
      <PageTitleHook
        title={`Compose Claim Request`}
        style={{ fontSize: "1.8em" }}
      />
      <hr />
      <ClientDataHook data={client} style={{ maxWidth: 750 }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <div
          className="form-group"
          style={{ width: "100%", marginBottom: "5px" }}
        >
          <label className="form-label">Presenting Complaints</label>
          <TextArea
            rows={3}
            style={{ width: "100%" }}
            onChange={(e) => setCompaint(e.target.value)}
          />
        </div>
        <div
          className="form-group"
          style={{ width: "100%", marginTop: "5px", marginBottom: "5px" }}
        >
          <label className="form-label">Diagnosis - Final</label>
          <DebounceSelect
            mode="multiple"
            value={value}
            placeholder="Select Final Diagnosis"
            fetchOptions={fetchDiagnosisList}
            onChange={addDiagnosis}
            style={{ width: "100%" }}
            loadingDiagnosis
          />
        </div>
      </div>
      <hr />
      <div style={{ maxHeight: "450px", overflowY: "auto" }}>
        <label className="form-label">Diagnosis - Provisional</label>
        <Select
          mode="multiple"
          value={provDiagenosis}
          style={{ width: "100%" }}
          disabled
        ></Select>
      </div>
      <div style={{ marginTop: 10 }}>
        <table className="table align-middle mb-0 bg-white">
          <thead style={{ fontWeight: "bolder" }}>
            <tr>
              <td>S/N</td>
              <td>Authorization Code</td>
              <td>Requested Item</td>
              <td>Quantity</td>
              <td>Cost</td>
              <td>Approved Cost</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              return Number(item.approved_cost) > 0 ? (
                <AuthorizationRequestDataListHook
                  data={item}
                  index={index}
                  user={user}
                />
              ) : null;
            })}
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "30px",
          gap: "5em",
          width: "100%",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        {/* <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', justifySelf: 'flex-start', gap: '5em'}}> */}
        <Popconfirm
          placement="top"
          title={"Are you sure you want to close this claim?"}
          onConfirm={() => navigator("/checkins")}
          okText="Yes"
          cancelText="No"
          disabled={
            loading || !items.filter((t) => (t.claim_item_status = "1")).length
          }
        >
          <Button
            style={{
              alignItems: "center",
              alignContent: "center",
              alignSelf: "flex-end",
              justifySelf: "flex-end",
            }}
            disabled={loading}
          >
            {/* {
                (loading)?
                  <Spin size="small" tip={'Sending Claim Request'} />: */}
            <span>
              <CloseOutlined /> Close{" "}
            </span>
            {/* } */}
          </Button>
        </Popconfirm>
        {/* </div> */}
        {[0, 2].includes(Number(user.role)) ? (
          // <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', margin: '30px', justifySelf: 'flex-end', gap: '5em'}}>
          <Popconfirm
            placement="top"
            title={"Are you sure you want to submit this claim?"}
            onConfirm={send_claim}
            okText="Yes"
            cancelText="No"
            disabled={
              loading ||
              !items.filter((t) => (t.claim_item_status = "1")).length
            }
          >
            <Button
              style={{
                alignItems: "center",
                alignContent: "center",
                alignSelf: "flex-end",
                justifySelf: "flex-start",
              }}
              disabled={
                loading ||
                !items.filter((t) => (t.claim_item_status = "1")).length
              }
            >
              {loading ? (
                <Spin size="small" tip={"Sending Claim..."} />
              ) : (
                <span>
                  Send <SendOutlined style={{ transform: "rotate(-30deg)" }} />
                </span>
              )}
            </Button>
          </Popconfirm>
        ) : // </div>
        null}
      </div>
    </>
  );
};

const AuthorizationRequestDataListHook = (props) => {
  const [docs, setDocs] = useState(fileses[props.data.auth_code]?.length);
  const [files, setFiles] = useState([]);
  const [menu, setMenu] = useState();

  console.log(props);

  const key = "pre";
  uploadData = { ...uploadData, checkin_id: props.data.checkin_id };

  useEffect(() => {
    setMenu(() => {
      const items = !files ? [] : files;
      const menu = items.map((k, i) => {
        return {
          label: (
            <Row
              gutter={[18, 8]}
              title={`Remove ${k.name}`}
              style={{ padding: ".2em .3em" }}
            >
              <Col span={20}>{k.name}</Col>
              <Col span={4}>
                <Button type="link">
                  <CloseCircleTwoTone />
                </Button>
              </Col>
            </Row>
          ),
          key: i,
          icon: <IoDocumentAttach />,
        };
      });
      return (
        <Menu
          items={menu}
          onClick={(e) => {
            notification.open({
              message: (
                <span>
                  Are you sure you want to remove{" "}
                  <strong>{files[e.key].name}</strong> from upload stack?
                </span>
              ),
              key,
              btn: (
                <>
                  <Button
                    type="primary"
                    danger
                    onClick={() => removeFile(e.key)}
                  >
                    Remove
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      notification.close(e.key);
                    }}
                  >
                    Close
                  </Button>
                </>
              ),
            });
          }}
        />
      );
    });
  }, [files]); //eslint-disable-line react-hooks/exhaustive-deps

  const removeFile = (fileIndex) => {
    fileses[props.data.auth_code] = fileses[props.data.auth_code].filter(
      (i, j) => j !== fileIndex,
    );
    setFiles(fileses[props.data.auth_code]);
    setDocs(fileses[props.data.auth_code].length);
    notification.close(key);
  };

  useEffect(() => {
    setDocs(files.length);
  }, [files]);

  const showModal = () => {
    document.querySelector(`#doc_${props.index}`).click();
  };

  const onChange = (event) => {
    const files = event.target.files;

    let r_files = [];

    if (!files.length) return;

    for (let file of files) {
      if (!allowableFiles.includes(file.type)) {
        notification.error({
          message: (
            <span>
              The <strong>{file.name}</strong> is not a PDF file. <br></br>
              <strong>Note: </strong>You can only upload a pdf file!
            </span>
          ),
        });
      } else {
        if (file.size / 1024 / 1024 > maxSize) {
          notification.error({
            message: (
              <span>
                The{" "}
                <strong>
                  {file.name} -- {(file.size / 1024 / 1024).toFixed(2)} MB
                </strong>{" "}
                is larger than the maximum allowable size of{" "}
                <strong>{maxSize}MB</strong>. <br></br>Kindly reduce the size
                and try again.
              </span>
            ),
          });
        } else {
          r_files.push(file);
        }
      }
    }

    if (Object.keys(fileses).includes(props.data.auth_code)) {
      fileses[props.data.auth_code] = [
        ...fileses[props.data.auth_code],
        ...r_files,
      ];
    } else {
      fileses[props.data.auth_code] = r_files;
    }

    fileses[props.data.auth_code] = fileses[props.data.auth_code].map(
      (i, j) => {
        const newName = `${props.data.auth_code.replaceAll(
          "/",
          "",
        )}_${uuid()}.${i.type.split("/")[1]}`;
        if (
          Object.keys(uploadData.filesHandles).includes(props.data.auth_code)
        ) {
          uploadData.filesHandles[props.data.auth_code] = [
            ...uploadData.filesHandles[props.data.auth_code],
            newName,
          ];
        } else {
          uploadData.filesHandles[props.data.auth_code] = [newName];
        }
        return renameFile(i, newName);
      },
    );

    setFiles(fileses[props.data.auth_code]);
    setDocs(fileses[props.data.auth_code].length);
  };

  return (
    <>
      <tr>
        <td className="fw-bold">{props.index + 1}</td>
        <td className="fw-bold">
          <span className="fw-normal mb-1 fw-bold">{props.data.auth_code}</span>
        </td>
        <td className="fw-bold">
          <span className="fw-normal mb-1 fw-bold">{`${
            props.data.category_name.split(" ")[0]
          } - ${props.data.item_name}`}</span>
        </td>
        <td>
          <span className="fw-normal mb-1 fw-bold">{props.data.quantity}</span>
        </td>
        <td>
          <span className="fw-normal mb-1 fw-bold">{props.data.cost}</span>
        </td>
        <td>
          <span className="fw-normal mb-1 fw-bold">
            {props.data.approved_cost}
          </span>
        </td>
        <td>
          {Number(props.data.category_id) === 3 &&
          [0, 2].includes(Number(props.user.role)) ? (
            <Dropdown.Button
              overlay={menu}
              onClick={showModal}
              placement={"top"}
            >
              <DeleteColumnOutlined /> Document ({docs})
              <input
                type="file"
                style={{ display: "none" }}
                id={`doc_${props.index}`}
                name="doc"
                onChange={onChange}
                multiple
              />
            </Dropdown.Button>
          ) : null}
        </td>
      </tr>
    </>
  );
};

function DebounceSelect({
  fetchOptions,
  loadingDiagnosis,
  aborter,
  debounceTimeout = 800,
  ...props
}) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [, setLoading] = useState(loadingDiagnosis);
  const fetchRef = React.useRef(0);

  useEffect(() => {
    fetchOptions().then((data) => setOptions(data));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const debounceFetcher = React.useMemo(() => {
    setLoading(false);
    const loadOptions = (value) => {
      if (!value.length) return;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions((d) => [
        { label: <Spin size="small" />, value: "_" },
        ...d.filter((i) => i.value !== "_"),
      ]);
      setFetching(true);
      fetchOptions(value)
        .then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            return;
          }
          setOptions((d) => [...newOptions]);
        })
        .finally(setFetching(false));
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? (
          <Spin size="small" tip="Loading ..." />
        ) : (
          <Empty
            description={<span>Empty</span>}
            style={{ padding: 50, width: "100%" }}
          />
        )
      }
      {...props}
      options={options}
    />
  );
}

const ClaimsListHook = (props) => {
  const user = JSON.parse(localStorage.getItem(config.key.user));
  const [, setClaimsList] = useState([]);
  const [filterdClaimList, setFilterdClaimsList] = useState([]);
  const [allClaims, setAllClaims] = useState({ paid: [], pending: [] });
  const [, setActiveSegment] = useState(0);
  const filterKeyword = useRef("");
  const navigate = useNavigate();
  const segment = useRef(
    props.section === "paid" ? 1 : props.section === "declined" ? 2 : 0,
  );

  useEffect(() => {
    fetchClaimsList(user.facility.facility_id)
      .then((res) => {
        const dat = NullSafe(res).sort(
          (a, b) => new Date(b.claims_date) - new Date(a.claims_date),
        );
        const allDat = sortClaims(dat);
        setClaimsList(dat);
        setFilterdClaimsList(() => {
          return segment.current
            ? Number(segment.current) === 2
              ? allDat.denied
              : allDat.paid
            : allDat.pending;
        });
        setAllClaims(allDat);
      })
      .catch((e) => {})
      .finally(() => {
        setInterval(() => {
          if (window.location.pathname !== "/claims") return;
          fetchClaimsList(user.facility.facility_id).then((res) => {
            const dat = NullSafe(res).sort(
              (a, b) => new Date(b.claims_date) - new Date(a.claims_date),
            );
            const sortedClaims = sortClaims(dat);
            setClaimsList(dat);
            setAllClaims(sortedClaims);
            setFilterdClaimsList(() => {
              if (segment.current) {
                if (filterKeyword.current) {
                  if (Number(segment.current) === 2) {
                    return sortedClaims.denied.filter((val) => {
                      return (
                        val.surname
                          .toLowerCase()
                          .indexOf(filterKeyword.current) >= 0 ||
                        val.first_name.indexOf(filterKeyword.current) >= 0 ||
                        val.claims_id.indexOf(filterKeyword.current) >= 0 ||
                        val.middle_name
                          .toLowerCase()
                          .indexOf(filterKeyword.current) >= 0 ||
                        val.insurance_no.indexOf(filterKeyword.current) >= 0 ||
                        val.checkin_id
                          .toLowerCase()
                          .indexOf(filterKeyword.current) >= 0 ||
                        val.claims_date.indexOf(filterKeyword.current) >= 0 ||
                        val.checkin_date.indexOf(filterKeyword.current) >= 0 ||
                        val.checkinType
                          .toLowerCase()
                          .indexOf(filterKeyword.current) >= 0
                      );
                    });
                  }
                  return sortedClaims.paid.filter((val) => {
                    return (
                      val.surname
                        .toLowerCase()
                        .indexOf(filterKeyword.current) >= 0 ||
                      val.first_name.indexOf(filterKeyword.current) >= 0 ||
                      val.claims_id.indexOf(filterKeyword.current) >= 0 ||
                      val.middle_name
                        .toLowerCase()
                        .indexOf(filterKeyword.current) >= 0 ||
                      val.insurance_no.indexOf(filterKeyword.current) >= 0 ||
                      val.checkin_id
                        .toLowerCase()
                        .indexOf(filterKeyword.current) >= 0 ||
                      val.claims_date.indexOf(filterKeyword.current) >= 0 ||
                      val.checkin_date.indexOf(filterKeyword.current) >= 0 ||
                      val.checkinType
                        .toLowerCase()
                        .indexOf(filterKeyword.current) >= 0
                    );
                  });
                } else {
                  if (segment.current === 2) return sortedClaims.denied;
                  return sortedClaims.paid;
                }
              } else {
                if (filterKeyword.current) {
                  return sortedClaims.pending.filter((val) => {
                    return (
                      (val.surname
                        .toLowerCase()
                        .indexOf(filterKeyword.current) >= 0 ||
                        val.first_name.indexOf(filterKeyword.current) >= 0 ||
                        val.claims_id.indexOf(filterKeyword.current) >= 0 ||
                        val.middle_name
                          .toLowerCase()
                          .indexOf(filterKeyword.current) >= 0 ||
                        val.insurance_no.indexOf(filterKeyword.current) >= 0 ||
                        val.checkin_id
                          .toLowerCase()
                          .indexOf(filterKeyword.current) >= 0 ||
                        val.claims_date.indexOf(filterKeyword.current) >= 0 ||
                        val.checkin_date.indexOf(filterKeyword.current) >= 0 ||
                        val.checkinType
                          .toLowerCase()
                          .indexOf(filterKeyword.current) >= 0) &&
                      Number(val.flag["code"]) !== 4
                    );
                  });
                } else {
                  return sortedClaims.pending;
                }
              }
            });
          });
        }, 10000);
      });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  async function fetchClaimsList(claimsId) {
    return fetch(`${uri}?claimsList`, {
      method: "POST",
      body: JSON.stringify({ facility_id: claimsId }),
      headers: {
        ...header,
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => response.json())
      .then((body) => {
        return body.data;
      }).catch(e => {
        console.log('Message: ', e?.message || e?.msg)
      });
  }

  const handleSegmentChange = (value) => {
    segment.current = value;
    switch (value) {
      case 0:
        setFilterdClaimsList(
          allClaims.pending.filter((val) => {
            return !filterKeyword.current
              ? true
              : (val.surname.toLowerCase().indexOf(filterKeyword.current) >=
                  0 ||
                  val.first_name.indexOf(filterKeyword.current) >= 0 ||
                  val.claims_id.indexOf(filterKeyword.current) >= 0 ||
                  val.middle_name
                    .toLowerCase()
                    .indexOf(filterKeyword.current) >= 0 ||
                  val.insurance_no.indexOf(filterKeyword.current) >= 0) &&
                  Number(val.flag["code"]) !== 4;
          }),
        );
        break;
      case 2:
        console.log(allClaims.denied);
        setFilterdClaimsList(
          allClaims.denied.filter((val) => {
            return !filterKeyword.current
              ? true
              : val.surname.toLowerCase().indexOf(filterKeyword.current) >= 0 ||
                  val.first_name.indexOf(filterKeyword.current) >= 0 ||
                  val.claims_id.indexOf(filterKeyword.current) >= 0 ||
                  val.middle_name
                    .toLowerCase()
                    .indexOf(filterKeyword.current) >= 0 ||
                  val.insurance_no.indexOf(filterKeyword.current) >= 0;
          }),
        );
        break;
      default:
        setFilterdClaimsList(
          allClaims.paid.filter((val) => {
            return !filterKeyword.current
              ? true
              : val.surname.toLowerCase().indexOf(filterKeyword.current) >= 0 ||
                  val.first_name.indexOf(filterKeyword.current) >= 0 ||
                  val.claims_id.indexOf(filterKeyword.current) >= 0 ||
                  val.middle_name
                    .toLowerCase()
                    .indexOf(filterKeyword.current) >= 0 ||
                  val.insurance_no.indexOf(filterKeyword.current) >= 0 ||
                  val.checkin_id.toLowerCase().indexOf(filterKeyword.current) >=
                    0 ||
                  val.claims_date.indexOf(filterKeyword.current) >= 0 ||
                  val.checkin_date.indexOf(filterKeyword.current) >= 0 ||
                  val.checkinType
                    .toLowerCase()
                    .indexOf(filterKeyword.current) >= 0;
          }),
        );
        break;
    }
    setActiveSegment(value);
  };

  const filterClaimsList = (e) => {
    const inputValue = e;
    if (inputValue) {
      setFilterdClaimsList(
        filterdClaimList.filter(
          (val) =>
            val.surname.toLowerCase().indexOf(inputValue) >= 0 ||
            val.first_name.indexOf(inputValue) >= 0 ||
            val.claims_id.indexOf(inputValue) >= 0 ||
            val.middle_name.toLowerCase().indexOf(inputValue) >= 0 ||
            val.insurance_no.indexOf(inputValue) >= 0 ||
            val.checkin_id.toLowerCase().indexOf(inputValue) >= 0 ||
            val.claims_date.indexOf(inputValue) >= 0 ||
            val.checkin_date.indexOf(inputValue) >= 0 ||
            val.checkinType.toLowerCase().indexOf(inputValue) >= 0,
        ),
      );
    } else {
      segment.current
        ? setFilterdClaimsList(allClaims.paid)
        : setFilterdClaimsList(allClaims.pending);
    }
  };

  const sortClaims = (claims) => {
    const batches = Array.from(new Set(claims.map((c) => c.payment_batch)));
    let dates = {};
    const pendingClaimSort = claims.filter(
      (cl) => Number(cl.flag["code"]) !== 4 && Number(cl.claims_status) < 3,
    );
    const deniedClaimSort = claims.filter((cl) => Number(cl.flag["code"]) === 4);
    const paidClaimSort = claims.filter((cl) => Number(cl.claims_status) === 3);
    let all_Claims = {
      paid: [],
      pending: pendingClaimSort,
      denied: deniedClaimSort,
    };

    claims.forEach((element) => {
      dates[element.payment_batch] = element.date_paid;
    });

    for (let b of batches) {
      if (b) {
        let datum = {
          payment_batch: b,
          payment_date: dates[b],
          claims: paidClaimSort.filter((p) => p.payment_batch === b),
        };
        all_Claims.paid.push(datum);
      }
    }

    return all_Claims;
  };

  const style = {
    padding: "8px 0",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "1.2em",
    fontWeight: "bold",
  };

  return (
    <>
      {props.extended && (
        <Segmented
          block
          options={[
            {
              label: (
                <div style={{ ...style, color: "#121221", cursor: "pointer" }}>
                  Pending
                </div>
              ),
              value: 0,
            },
            {
              label: (
                <div style={{ ...style, color: "#00aa00", cursor: "pointer" }}>
                  Paid
                </div>
              ),
              value: 1,
            },
            {
              label: (
                <div style={{ ...style, color: "#f5222d", cursor: "pointer" }}>
                  Denied
                </div>
              ),
              value: 2,
            },
          ]}
          onChange={handleSegmentChange}
          style={{ userSelect: "none" }}
        />
      )}

      <div style={{ marginTop: 10 }}>
        {props.extended && (
          <Row justify="end" align="middle">
            <Col span={2}>
              <span style={{ fontWeight: "bolder", fontSize: "1.2em" }}>
                Search:
              </span>
            </Col>
            <Col span={8}>
              <Input
                placeholder="Type Filter Word"
                onChange={(e) =>
                  filterClaimsList(
                    (filterKeyword.current = e.target.value.toLowerCase()),
                  )
                }
              />
            </Col>
          </Row>
        )}
        {!filterdClaimList?.length ? (
          <>
            <Empty
              description={<span>There are no claims in this category</span>}
              style={{ padding: 50, width: "100%" }}
            >
              {props.extended && (
                <Button type="primary" onClick={() => navigate("/checkins")}>
                  File a Claim Now
                </Button>
              )}
            </Empty>
          </>
        ) : (
          <>
            {segment.current
              ? Number(segment.current) !== 2
                ? filterdClaimList.map((claim, index) => (
                    <PaymentBatchListing
                      claim={claim}
                      index={index}
                      extended={props.extended}
                    />
                  ))
                : filterdClaimList.map((claim) => (
                    <ClaimItemHandler claim={claim} extended={props.extended} />
                  ))
              : filterdClaimList.map((claim) => (
                  <ClaimItemHandler claim={claim} extended={props.extended} />
                ))}
          </>
        )}
      </div>
    </>
  );
};

const ClaimItemHandler = ({ claim, extended }) => {
  const navigate = useNavigate();

  return (
    <Row style={{ padding: "5px 0" }}>
      <Col span={24}>
        {claim.aggregatedClaimsItemsStatus ? (
          <Badge.Ribbon
            text={
              <>
                <Badge count={claim.queries || null} offset={[10, -8]}>
                  <span style={{ color: "white" }}>{claim.flag.text}</span>
                </Badge>
              </>
            }
            // text={claim.flag.text}
            color={claim.flag.color}
            placement="end"
          >
            <Row
              justify="space-between"
              style={{
                padding: "2px 10px",
                boxShadow: "2px 2px 5px rgba(0,0,0, .4)",
              }}
              align="middle"
              onClick={() => {
                navigate(`/claims-action?encounter=${claim.checkin_id}`);
              }}
            >
              <Col span={extended ? 1 : 2}>
                <Avatar
                  size={64}
                  icon={
                    <img
                      src={`${uri_img}${claim.pic_name}`}
                      alt={claim.first_name}
                    />
                  }
                />
              </Col>
              {extended && (
                <Col span={2}>
                  <p>{claim.code.split(".")[1]}</p>
                </Col>
              )}

              <Col span={4} alignItems="center">
                <p>
                  {Array.from(claim.surname)
                    .map((i, v) => i.toLocaleUpperCase())
                    .join("")}
                  {extended && ", &nbsp"};
                  {extended &&
                    Array.from(claim.first_name)
                      .map((i, v) => (v === 0 ? i.toLocaleUpperCase() : i))
                      .join("")}{" "}
                  &nbsp;
                  {extended &&
                    Array.from(claim.middle_name)
                      .map((i, v) => (v === 0 ? i.toLocaleUpperCase() : i))
                      .join("")}
                </p>
              </Col>
              <Col span={extended ? 3 : 6}>
                <p>{new Date(claim.checkin_date).toLocaleString()}</p>
              </Col>
              <Col span={1}>
                <p>{claim.pa_items_count}</p>
              </Col>
              {/* Preauth Items Status List */}
              {extended && (
                <Col span={4}>
                  <Row>
                    <Col span={20}>
                      <strong>Preauthourized Items</strong>
                    </Col>
                  </Row>
                  {claim.aggregatedItemsStatus.cat.Pending.count ? (
                    <Row justify="spac-between">
                      <Col
                        span={12}
                        style={{
                          color: `${claim.aggregatedItemsStatus.cat.Pending.color}`,
                        }}
                      >
                        {claim.aggregatedItemsStatus.cat.Pending.text}:
                      </Col>
                      <Col>{claim.aggregatedItemsStatus.cat.Pending.count}</Col>
                    </Row>
                  ) : null}
                  {claim.aggregatedItemsStatus.cat.Queried.count ? (
                    <Row justify="spac-between">
                      <Col
                        span={12}
                        style={{
                          color: `${claim.aggregatedItemsStatus.cat.Queried.color}`,
                        }}
                      >
                        {claim.aggregatedItemsStatus.cat.Queried.text}:
                      </Col>
                      <Col>{claim.aggregatedItemsStatus.cat.Queried.count}</Col>
                    </Row>
                  ) : null}
                  {claim.aggregatedItemsStatus.cat.Approved.count ? (
                    <Row justify="spac-between">
                      <Col
                        span={12}
                        style={{
                          color: `${claim.aggregatedItemsStatus.cat.Approved.color}`,
                        }}
                      >
                        {claim.aggregatedItemsStatus.cat.Approved.text}:
                      </Col>
                      <Col>
                        {claim.aggregatedItemsStatus.cat.Approved.count}
                      </Col>
                    </Row>
                  ) : null}
                  {claim.aggregatedItemsStatus.cat.Declined.count ? (
                    <Row justify="spac-between">
                      <Col
                        span={12}
                        style={{
                          color: `${claim.aggregatedItemsStatus.cat.Declined.color}`,
                        }}
                      >
                        {claim.aggregatedItemsStatus.cat.Declined.text}:
                      </Col>
                      <Col>
                        {claim.aggregatedItemsStatus.cat.Declined.count}
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              )}
              {/* Claims Items Status List */}
              <Col span={extended ? 4 : 6}>
                <Row>
                  <Col span={20}>
                    <strong>Claimed Items</strong>
                  </Col>
                </Row>
                {claim.aggregatedClaimsItemsStatus.cat.Pending.count ? (
                  <Row justify="spac-between">
                    <Col
                      span={12}
                      style={{
                        color: `${claim.aggregatedClaimsItemsStatus.cat.Pending.color}`,
                      }}
                    >
                      {claim.aggregatedClaimsItemsStatus.cat.Pending.text}:
                    </Col>
                    <Col>
                      {claim.aggregatedClaimsItemsStatus.cat.Pending.count}
                    </Col>
                  </Row>
                ) : null}
                {claim.aggregatedClaimsItemsStatus.cat.Queried.count ? (
                  <Row justify="spac-between">
                    <Col
                      span={12}
                      style={{
                        color: `${claim.aggregatedClaimsItemsStatus.cat.Queried.color}`,
                      }}
                    >
                      {claim.aggregatedClaimsItemsStatus.cat.Queried.text}:
                    </Col>
                    <Col>
                      {claim.aggregatedClaimsItemsStatus.cat.Queried.count}
                    </Col>
                  </Row>
                ) : null}
                {claim.aggregatedClaimsItemsStatus.cat.Approved.count ? (
                  <Row justify="spac-between">
                    <Col
                      span={12}
                      style={{
                        color: `${claim.aggregatedClaimsItemsStatus.cat.Approved.color}`,
                      }}
                    >
                      {claim.aggregatedClaimsItemsStatus.cat.Approved.text}:
                    </Col>
                    <Col>
                      {claim.aggregatedClaimsItemsStatus.cat.Approved.count}
                    </Col>
                  </Row>
                ) : null}
                {claim.aggregatedClaimsItemsStatus.cat.Declined.count ? (
                  <Row justify="spac-between">
                    <Col
                      span={12}
                      style={{
                        color: `${claim.aggregatedClaimsItemsStatus.cat.Declined.color}`,
                      }}
                    >
                      {claim.aggregatedClaimsItemsStatus.cat.Declined.text}:
                    </Col>
                    <Col>
                      {claim.aggregatedClaimsItemsStatus.cat.Declined.count}
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          </Badge.Ribbon>
        ) : null}
      </Col>
    </Row>
  );
};

const ClientDataHook = (props) => {
  const style = {
    ...props.style,
    display: "flex",
    color: "black",
    fontSize: "1.3em",
    textAlign: "left",
    padding: ".2rem",
    borderWidth: ".2em",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, .4)",
    borderRadius: ".5em",
  };

  return (
    <>
      <Row className="card-c" style={style} align="middle">
        <Col span={4} className="img-round img-medium">
          <img
            src={
              props.data.pic_name ? `${uri_img}${props.data.pic_name}` : logo
            }
            alt=""
            style={{
              width: "120px",
              height: "120px",
              display: "flex",
              alignSelf: "center",
              justifySelf: "center",
              margin: ".3em",
            }}
            className="rounded-circle"
          />
        </Col>
        <Col
          span={20}
          className="card-c-details"
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            justifySelf: "center",
            paddingLeft: "30px",
          }}
        >
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Full Name:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props.data.surname ? props.data.surname.toUpperCase() : ""},{" "}
              {props.data.first_name ? props.data.first_name : ""}{" "}
              {props.data.middle_name ? props.data.middle_name : ""}
            </Col>
          </Row>
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Insurance ID:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props.data.insurance_no ? props.data.insurance_no : ""}
            </Col>
          </Row>
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Premium Type:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props.data.insurance_package
                ? props.data.insurance_package
                : ""}{" "}
              | {props.data.premium_type ? props.data.premium_type : ""}
            </Col>
          </Row>
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Premium Status:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props.data.status ? `Active` : "Expired"}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const PaymentBatchListing = ({ claim, index }) => {
  const [, setClaimsIdsOfTheBatch] = useState([]);
  const [dataWVew, setDataView] = useState(false);
  const navigate = useNavigate();

  const handleView = (index) => {
    setDataView(!dataWVew);
    setClaimsIdsOfTheBatch(() => claim.claims.map((c, i) => c.claims_id));
  };

  const openClaim = (claimId) => {
    navigate(`/claims-action?encounter=${claimId}`);
  };

  return (
    <Row style={{ padding: "5px 0" }}>
      <Col span={24}>
        <Row
          justify="start"
          align="middle"
          style={{
            padding: "2px 10px",
            boxShadow: "2px 2px 5px rgba(0,0,0, .4)",
            cursor: "pointer",
          }}
          onClick={() => handleView(index)}
        >
          <Col span={2}>
            <Avatar size={64} icon={<RiHospitalFill />} />
          </Col>
          <Col span={6}>
            <Row justify="space-between">
              <Col span={24}>Payment Batch No.:</Col>
              <Col span={24}>{claim.payment_batch}</Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row justify="start">
              <Col span={12}>Claims:</Col>
              <Col>{claim.claims.length}</Col>
            </Row>
            <Row justify="start">
              <Col span={12}>Approved Cost:</Col>
              <Col>
                <CurrencyFormat
                  value={claim.claims.reduce(
                    (prev, curr) => prev + Number(curr.encounter_cost),
                    0,
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
              </Col>
            </Row>
          </Col>
          <Col span={7}>
            <Row gutter={[6, 6]}>
              <Col span={12}>
                <Button
                  type="primary"
                  onClick={() => handleView(index)}
                  style={{ width: "100%" }}
                >
                  View Claims
                </Button>
              </Col>
              {Number(claim?.claims[0]?.claims_status) === 3 &&
              claim?.claims[0]?.payment_batch ? (
                <Col span={12}>
                  <Button
                    type="link"
                    href={`${uri}documents/claims/payments/claims/${claim.payment_batch}.pdf`}
                    target={"_blank"}
                  >
                    Payment Document
                  </Button>
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col span={3}>
            <Row justify="start">
              <Col span={24}>Payment Date:</Col>
            </Row>
            <Row justify="start">
              <Col span={24}>
                {new Date(claim.payment_date).toLocaleString()}
              </Col>
            </Row>
          </Col>
        </Row>
        {dataWVew ? (
          <div style={{ overflowY: "auto", marginTop: 10, padding: 20 }}>
            {claim.claims.map((claim, index) => (
              <Row style={{ padding: "5px 0" }}>
                <Col span={24}>
                  {claim.aggregatedItemsStatus ? (
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{
                        padding: "2px 10px",
                        boxShadow: "2px 2px 5px rgba(0,0,0, .4)",
                        cursor: "pointer",
                      }}
                      onClick={() => openClaim(claim.checkin_id)}
                    >
                      <Col span={1}>
                        <Avatar
                          size={64}
                          icon={
                            <img
                              src={`${uri_img}${claim.pic_name}`}
                              alt={claim.first_name}
                            />
                          }
                        />
                      </Col>
                      <Col span={2}>
                        <p>{claim.claims_id.split(".")[1]}</p>
                      </Col>
                      <Col span={4}>
                        <p>
                          {Array.from(claim.surname)
                            .map((i, v) => i.toLocaleUpperCase())
                            .join("")}
                          , &nbsp;
                          {Array.from(claim.first_name)
                            .map((i, v) => (v === 0 ? i.toLocaleUpperCase() : i))
                            .join("")}{" "}
                          &nbsp;
                          {Array.from(claim.middle_name)
                            .map((i, v) => (v === 0 ? i.toLocaleUpperCase() : i))
                            .join("")}
                        </p>
                      </Col>
                      <Col span={3}>
                        <p>{new Date(claim.claims_date).toLocaleString()}</p>
                      </Col>
                      <Col span={1}>
                        <p>{claim.pa_items_count}</p>
                      </Col>
                      <Col span={4}>
                        <p>{new Date(claim.claims_date).toLocaleString()}</p>
                      </Col>
                      <Col span={4}>
                        <Row justify="space=between">
                          <Col
                            span={12}
                            style={{
                              color: `${claim.aggregatedItemsStatus.cat.Pending.color}`,
                            }}
                          >
                            {claim.aggregatedItemsStatus.cat.Pending.text}:
                          </Col>
                          <Col>
                            {claim.aggregatedItemsStatus.cat.Pending.count}
                          </Col>
                        </Row>
                        <Row justify="space=between">
                          <Col
                            span={12}
                            style={{
                              color: `${claim.aggregatedItemsStatus.cat.Queried.color}`,
                            }}
                          >
                            {claim.aggregatedItemsStatus.cat.Queried.text}:
                          </Col>
                          <Col>
                            {claim.aggregatedItemsStatus.cat.Queried.count}
                          </Col>
                        </Row>
                        <Row justify="space=between">
                          <Col
                            span={12}
                            style={{
                              color: `${claim.aggregatedItemsStatus.cat.Approved.color}`,
                            }}
                          >
                            {claim.aggregatedItemsStatus.cat.Approved.text}:
                          </Col>
                          <Col>
                            {claim.aggregatedItemsStatus.cat.Approved.count}
                          </Col>
                        </Row>
                        <Row justify="space=between">
                          <Col
                            span={12}
                            style={{
                              color: `${claim.aggregatedItemsStatus.cat.Declined.color}`,
                            }}
                          >
                            {claim.aggregatedItemsStatus.cat.Declined.text}:
                          </Col>
                          <Col>
                            {claim.aggregatedItemsStatus.cat.Declined.count}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
            ))}
          </div>
        ) : null}
        {/* </Badge.Ribbon> */}
      </Col>
    </Row>
  );
};

const ClaimsHook = (props) => {
  const extended = props.extended !== undefined ? props.extended : true;
  const section = props.section !== undefined ? props.section : "all";

  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { set, user } = useAuth();
  const dispatcher = useDispatch(selectclient);

  useEffect(() => {
    if (user) {
      if (![0, 1, 2, 3].includes(Number(user.role))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: "Invalid User",
          description: "Please login with the right permission",
        });
        navigate("/login");
      } else {
        if (params.get("checkin")) {
          fetch(uri + "?userByCheckinId", {
            method: "post",
            body: JSON.stringify({ checkinId: params.get("checkin") }),
            headers: {
              ...header,
              Authorization: `Bearer ${user.jwt}`,
            },
          })
            .then((res) => res.json())
            .then((d) => {
              if (d.error) {
                notification.error({
                  message: d.message,
                  duration: 2,
                });
                navigate("/checkins");
              }
            }).catch(e => {
              console.log('Message: ', e?.message || e?.msg)
            });
        }
      }
    } else {
      navigate("/login");
    }
  });


  return (
    <>
      {params.get("checkin") ? (
        <>
          <ClaimsRequestHook extended={extended} section={section} />
        </>
      ) : (
        <ClaimsListHook extended={extended} section={section} />
      )}
    </>
  );
};

export default ClaimsHook;
