import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  Button,
  Row,
  Col,
  Empty,
  Modal,
  notification,
  Menu,
  Typography,
  Drawer,
  Space,
  Table,
  InputNumber,
  Spin,
} from "antd";
import {
  Loading3QuartersOutlined,
  EditTwoTone,
  MenuUnfoldOutlined,
  CheckSquareTwoTone,
  MessageTwoTone,
  DeleteTwoTone,
} from "@ant-design/icons";

import "./index.css";
import {
  fetchPaginatedData,
  myRequest,
  removeDuplicates,
  updateChangedObjects,
} from "../../assets/utils/lib";
import config from "../../assets/utils/config";
import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;

const Pricing = () => {
  const { confirm } = Modal;

  const [costableItemsLoading, setCostableItemsLoading] = useState(false);
  const [filedItemsLoading, setFiledItemsLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editing, ] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [submittingEdit, setSubmittingEdit] = useState(false);
  const [startFiling, setStartFiling] = useState(false);
  const [allDataPulled, setAllDataPulled] = useState(false);

  const [currentActivity, setCurrentActivity] = useState(1);

  const [, setShowFileTariff] = useState(false);
  const [, setShowTariffs] = useState(false);

  const [categoryItems, setCategoryItems] = useState([]);
  const [categoryItemsToShow, setCategoryItemsToShow] = useState([]);
  const [categoryCategories, setCategoryCategories] = useState([]);

  const [filing, setFiling] = useState({});
  const [filingsArray, setFilingsArray] = useState([]);

  const [filingsArrayTable, setFilingsArrayTable] = useState([]);

  const [filedArray, ] = useState([]);

  const [itemToEdit, setItemToEdit] = useState({});

  const [messaging, setMessaging] = useState(false);
  const [messagingItem, setMessagingItem] = useState({});

  useEffect(() => {
    if (!allDataPulled) {
      fetchCostable();
      fetCategories();
    }
  }, [allDataPulled]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const daa = [];
    categoryItems.map((filed) => {
      if (filedArray.find((c) => c.item_id === filed.id)) daa.push(filed);
      return null
    });
  }, [filedArray]); //eslint-disable-line react-hooks/exhaustive-deps

  const [n_data, set_N_Data] = useState([]);

  const fetchCostable = async () => {
    if (allDataPulled) return;

    setCostableItemsLoading(true);
    setFiledItemsLoading(true);

    const daa = [];
    const daa_ = [];

    for await (const data of fetchPaginatedData("post", "filedItems", {})) {
      set_N_Data((d) => [...data, ...d]);
      daa.push(...data);
    }

    set_N_Data(removeDuplicates(daa, "item_code"));
    setFiledItemsLoading(false);

    for await (const data of fetchPaginatedData(
      "post",
      "itemsListPricing",
      {},
    )) {
      const newItems = await data;
      newItems.forEach((element) => {
        if (!n_data.find((c) => c.item_code === element.item_code))
          daa_.push(element);
      });
      setCategoryItems(removeDuplicates(daa_, "item_code"));
    }

    setCostableItemsLoading(false);
    setAllDataPulled(true);
  };

  const fetCategories = async () => {
    if (allDataPulled) return;
    const url = `constableCategoriesList`;
    myRequest("post", url, {})
      .then((response) => response.data)
      .then((data) => {
        if (!data?.error) {
          const netVal = data.data;
          setCategoryCategories(netVal);
        }
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "item_name",
      key: "item_name",
      render: (text) => <Button type="link">{text}</Button>,
    },
    {
      title: "Category",
      dataIndex: "category_name",
      key: "category_name",
    },
    {
      title: "Brand",
      dataIndex: "item_brand",
      key: "item_brand",
    },
    {
      title: "Strength",
      dataIndex: "item_strength",
      key: "item_strength",
    },
    {
      title: "Form",
      dataIndex: "item_presentation",
      key: "item_presentation",
    },
    {
      title: "Proposed Cost",
      dataIndex: "cost_facility",
      key: "cost_facility",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => delet(_, record)}>Delete</Button>
        </Space>
      ),
    },
  ];

  const dropdownItems = [
    {
      key: 0,
      label: "Action",
      icon: <MenuUnfoldOutlined />,
      disable: deleting || editing,
      children: [
        {
          key: 1,
          label: "Accept HMO Cost",
          icon: <CheckSquareTwoTone />,
          disable: deleting || editing,
        },
        {
          key: 2,
          label: "Edit Record",
          icon: <EditTwoTone />,
          disable: deleting || editing,
        },
        {
          key: 3,
          label: "Messages",
          icon: <MessageTwoTone />,
          disable: deleting || editing,
        },
        {
          key: 4,
          label: "Delete Record",
          icon: <DeleteTwoTone />,
          disable: deleting || editing,
        },
      ],
    },
  ];

  const menuAction = (e, _, record) => {
    switch (e.key) {
      case "1":
        setItemToEdit(record);
        record = {
          ...record,
          cost_facility: record.cost_hmo,
          cost: record.cost_hmo,
          pricing_status: 1,
        };
        saveChange(record, itemToEdit);
        break;
      case "2":
        editRecord(_, record);
        break;
      case "3":
        showMessage(e, _, record);
        break;
      case "4":
        delet_(_, record);
        break;
      default:
        break;
    }
  };

  const columns_ = [
    {
      title: "Name",
      dataIndex: "item_name",
      key: "item_name",
      render: (text) => <Button type="link">{text}</Button>,
    },
    {
      title: "Category",
      dataIndex: "category_name",
      key: "category_name",
    },
    {
      title: "Brand",
      dataIndex: "item_brand",
      key: "item_brand",
    },
    {
      title: "Strength",
      dataIndex: "item_strength",
      key: "itemstrength",
    },
    {
      title: "Form",
      dataIndex: "item_presentation",
      key: "item_presentation",
    },
    {
      title: "Approved Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "Proposed Costs",
      children: [
        {
          title: "Facility",
          dataIndex: "cost_facility",
          key: "cost_facility",
        },
        {
          title: "HMO",
          dataIndex: "cost_hmo",
          key: "cost_hmo",
        },
      ],
    },
    {
      title: "Status",
      dataIndex: "costable_pricing_status_name",
      key: "costable_pricing_status_name",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle" direction="vertical">
          <Menu
            items={dropdownItems}
            onClick={(e) => menuAction(e, _, record)}
            trigger={"click"}
          >
            Actions
          </Menu>
        </Space>
      ),
    },
  ];

  const selectCateogryItems = (e) => {
    setFiling({ category_id: e });
    const daa = [];
    categoryItems.map((item) => {
      if (
        !n_data.find((c) => c.item_id === item.id) &&
        !filedArray.find((c) => c.item_id === item.id) &&
        item.category_id === e
      )
        daa.push(item);
      return null
    });
    setCategoryItemsToShow(daa);
  };

  const addItem = (e) => {
    if (
      Object.keys(filing).length &&
      filing?.category_id &&
      filing?.item_id &&
      filing?.cost_facility
    ) {
      setFilingsArray((d) => [...d, filing]);
    }
    setFiling({ category_id: filing?.category_id });
  };

  const saveChange = (record, Changes) => {
    if (Changes?.cost_facility === record.cost_facility) {
      notification.info({ message: "No Changes were made!" });
      return;
    }

    setSubmittingEdit(true);
    myRequest("post", "editPricingItem", record)
      .then((response) => response.data)
      .then((data) => {
        if (!data?.error) {
          notification.success({ message: data?.message });
          set_N_Data(
            n_data.map((item) =>
              item.item_code === record.item_code ? record : item,
            ),
          );
        } else {
          notification.error({ message: data?.message });
        }
      })
      .finally(() => setSubmittingEdit(false));
  };

  const editRecord = (item, record) => {
    setItemToEdit(record);
    confirm({
      width: 400,
      title: <>Edit Record for {record.item_code}</>,
      icon: <EditTwoTone />,
      content: (
        <>
          <Space direction="horizontal">
            <Typography.Text>New Item Cost_facility:</Typography.Text>
            <InputNumber
              value={Number(record.cost_facility)}
              disabled={submittingEdit}
              onChange={(e) =>
                (record = { ...record, cost_facility: Number(e) })
              }
            />
          </Space>
        </>
      ),
      okText: "Save Change",
      cancelText: "Close",
      onCancel: () => {
        setItemToEdit({});
      },
      onOk: () => saveChange(record, itemToEdit),
    });
  };

  useEffect(() => {
    const filingObjForTable = [];
    filingsArray.map((filingArrayItem) => {
      const category = categoryCategories.find(
        (item) => item.id === filingArrayItem.category_id,
      );
      const item = categoryItems.find(
        (item) => item.id === filingArrayItem.item_id,
      );
      filingObjForTable.push({ ...filingArrayItem, ...category, ...item });
      return null
    });
    setFilingsArrayTable(filingObjForTable);
  }, [filingsArray]); //eslint-disable-line react-hooks/exhaustive-deps

  const delet = (_, record) => {
    setFilingsArray(filingsArray.filter((item) => item.item_id !== record.id));
  };

  const delet_ = (_, record) => {
    const url = `removeFiledItem`;
    setDeleting(true);
    myRequest("post", url, record)
      .then((response) => response.data)
      .then((data) => {
        if (!data?.error) {
          notification.success({ message: data.message });
          set_N_Data(
            n_data.filter((item) => item.item_code !== record.item_code),
          );
        } else {
          notification.error({ message: data.message });
        }
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const clearnFilings = () => {
    setFilingsArray([]);
  };

  const submitFilings = () => {
    // setStartFiling(false)
    setSubmitting(true);
    myRequest(
      "post",
      "fileForPricing",
      filingsArray.map((item) => {
        delete item.category_id;
        return item;
      }),
    )
      .then((response) => response.data)
      .then((data) => {
        if (!data?.error) {
          notification.success({ message: data?.message });
          set_N_Data((d) => [...d, ...data?.data]);
          setFilingsArray([]);
        } else {
          notification.error({ message: data?.message });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const loadData = async (e) => {
    setFiledItemsLoading(true);
    const url = `filedItems`;
    const daa = [];
    for await (const data of fetchPaginatedData("post", url, {})) {
      set_N_Data((d) => [...data, ...d]);
      daa.push(...data);
    }
    set_N_Data(removeDuplicates(daa, "item_code"));
    setFiledItemsLoading(false);
  };

  const showMessage = async (e, text, record) => {
    setMessagingItem(record);
    setMessaging(true);
  };

  return (
    <div style={{ width: "100%", margin: "20px 0 5px 0", padding: 0 }}>
      <Row gutter={[12, 12]}>
        <Col span={3} md={3} sm={12} lg={4}>
          <Button
            style={{ width: "100%" }}
            onClick={() => {
              setShowTariffs(true);
              setCurrentActivity(1);
            }}
            disabled={startFiling}
          >
            Tarrifs
          </Button>
        </Col>
        <Col span={3} md={3} sm={12} lg={4}>
          <Button
            style={{ width: "100%", backgroundColor: startFiling && "#69b1ff" }}
            onClick={() => {
              setShowFileTariff(true);
              setCurrentActivity(2);
            }}
            disabled={startFiling}
          >
            Propose New Tarrif(s)
          </Button>
        </Col>
      </Row>
      {currentActivity === 2 && (
        <Space
          direction="vertical"
          style={{ width: "100%", margin: "20px 0 20px 0" }}
        >
          {startFiling ? (
            <>
              <Row gutter={[12, 12]} align={"middle"} justify={"space-between"}>
                <Col span={24}>
                  <Select
                    searchValue=""
                    placeholder="Select Costing Category"
                    style={{ width: "30%" }}
                    onChange={selectCateogryItems}
                    value={filing?.category_id}
                  >
                    {categoryCategories.map((category) => (
                      <Option
                        value={category?.id}
                        key={category?.id}
                        title={category?.category_description}
                      >
                        {category?.category_name}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    showSearch
                    value={filing?.item_id}
                    suffixIcon={
                      costableItemsLoading && <Loading3QuartersOutlined spin />
                    }
                    onChange={(e) => setFiling((d) => ({ ...d, item_id: e }))}
                    placeholder={
                      categoryItemsToShow.length
                        ? "Select Costing Item"
                        : "Kindly Select a Category"
                    }
                    style={{ width: "30%" }}
                    disabled={!categoryItemsToShow.length}
                  >
                    {categoryItemsToShow.map(
                      (item) =>
                        !filingsArray.find((c) => c.item_id === item.id) && (
                          <Option value={item?.id} key={item.id}>
                            <span>
                              <strong>Item Name: </strong>
                              {item?.item_name}
                            </span>
                            <br></br>
                            <span>
                              <strong>Item Code: </strong>
                              {item?.item_code}
                            </span>
                            <br></br>
                            <span>
                              <strong>Item Brand: </strong>
                              {item?.item_brand}
                            </span>
                            <br></br>
                            <span>
                              <strong>Item Strength: </strong>
                              {item?.item_strength}
                            </span>
                            <br></br>
                            <span>
                              <strong>Item Presentation: </strong>
                              {item?.item_presentation}
                            </span>
                          </Option>
                        ),
                    )}
                  </Select>
                  <InputNumber
                    value={filing?.cost_facility}
                    placeholder="Proposed Cost"
                    style={{ width: "30%" }}
                    onChange={(e) =>
                      setFiling((d) => ({ ...d, cost_facility: e }))
                    }
                    disabled={!filing?.item_id}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "10%" }}
                    onClick={addItem}
                    disabled={
                      Object.keys(filing).length < 3 || !filing?.cost_facility
                    }
                  >
                    {" "}
                    Add{" "}
                  </Button>
                </Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    onClick={submitFilings}
                    disabled={submitting}
                    loading={submitting}
                  >
                    Submit
                  </Button>
                </Col>
                <Col span={4}>
                  <Button
                    type="dotted"
                    danger
                    style={{ width: "50%" }}
                    onClick={() => setStartFiling(false)}
                    disabled={submitting}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    danger
                    style={{ width: "50%" }}
                    onClick={clearnFilings}
                    disabled={submitting}
                  >
                    Clear Filings
                  </Button>
                </Col>
              </Row>
              <Space
                direction="vertical"
                gutter={[12, 12]}
                justify={"space-between"}
                style={{ width: "100%" }}
              >
                <Table columns={columns} dataSource={filingsArrayTable} />
              </Space>
            </>
          ) : (
            <Button type="primary" onClick={() => setStartFiling(true)}>
              Start Filing
            </Button>
          )}
        </Space>
      )}

      {currentActivity === 1 && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Space
            direction="vertical"
            justify={"space-between"}
            style={{ width: "100%" }}
          >
            <Row justify={"end"}>
              <Col span={4}>
                <Button
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  icon={<Loading3QuartersOutlined />}
                  onClick={loadData}
                  disabled={filedItemsLoading}
                  loading={filedItemsLoading}
                >
                  Load Data
                </Button>
              </Col>
            </Row>
            <Table columns={columns_} dataSource={n_data} />
          </Space>
        </Space>
      )}

      {messaging && (
        <PAQuery
          show={messaging}
          data={messagingItem}
          close={() => setMessaging(false)}
        />
      )}
    </div>
  );
};

const PAQuery = ({ show, data, close }) => {
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sending, setsending] = useState(false);
  const [visible, setVisible] = useState(show);
  const [messageText, setMessageText] = useState("");

  const [messageData, ] = useState({
    relation: "costable_pricing",
    marker: data?._id,
    source: 1,
    facility_type: 1,
  });

  const [, setFirstLoad] = useState(true);
  const [, setReading] = useState(false);

  let interval = useRef();

  useEffect(() => {
    let intervalId; // Variable to store the interval ID

    const fetchAndUpdateQueries = () => {
      fetchQueries(visible).then((res) => {
        setLoading(false);
      });
    };

    if (visible) {
      setLoading(true);
      localStorage.setItem(config.key.q_reading, 1);
      fetchAndUpdateQueries();
      intervalId = setInterval(fetchAndUpdateQueries, 5000);
    } else {
      setReading(false);
      localStorage.setItem(config.key.q_reading, 0);
      clearInterval(intervalId); // Clear the interval
    }

    return () => {
      clearInterval(intervalId); // Cleanup: Clear the interval on unmount or when `visible` changes
    };
  }, [visible]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const queriesToUpdate = queries
      .filter((j) => !Number(j.status) && !Number(j.source))
      .map((i) => {
        return {
          id: i._id,
        };
      });

    if (visible && queriesToUpdate.length) {
      myRequest("post", `updateMessage`, {
        content: messageText,
        ...messageData,
      })
        .catch((e) => console.log(e.msg ?? e.message))
        .finally(setFirstLoad(false));
    }
  }, [visible, queries]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleOk = () => {
    setsending(true);
    myRequest("post", `message`, { content: messageText, ...messageData })
      .then((res) => res.data)
      .then((body) => {
        if (!body.error) {
          //   dispatcher(add_queries(body.data));
        }
        setsending(false);
        setMessageText("");
      });
  };

  const handleCancel = () => {
    clearInterval(interval.current);
    setVisible(false);
    setReading(false);
    close();
  };

  const composeMessage = (e) => {
    const mess = e.target.value;
    setMessageText(mess);
  };

  const fetchQueries = async () => {
    for await (const data of fetchPaginatedData(
      "post",
      "messages",
      messageData,
    )) {
      setQueries((d) => [...updateChangedObjects(d, data)]);
    }
  };

  return (
    <>
      <Drawer
        open={show}
        title={
          <>
            <Typography.Title level={5} style={{ margin: "0 auto" }}>
              Messages: {data?.item_code} - {data?.item_name}
            </Typography.Title>
          </>
        }
        onClose={handleCancel}
        footer={[
          <Row justify="space-between" align="bottom">
            <Col span={20}>
              <TextArea
                rows={3}
                value={messageText}
                placeholder="Write Full Description of the Query...."
                onChange={composeMessage}
                id={"message"}
              />
            </Col>
            <Col>
              <Button
                key="back"
                onClick={handleOk}
                style={{ color: "skyblue", fontSize: "1em" }}
                disabled={!messageText.length || sending}
              >
                {" "}
                Send{" "}
              </Button>
            </Col>
          </Row>,
        ]}
        width={500}
      >
        <Space
          gutter={[8, 16]}
          direction={"vertical"}
          id="chatContainer"
          style={{ width: "100%" }}
        >
          {loading ? (
            <Col>
              <Spin size="large" />
            </Col>
          ) : !queries.length ? (
            <Empty description={<span>No Converation</span>} />
          ) : (
            <>
              {queries
                .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
                .map((q, i) => (
                  <Row
                    id={`query_${i}`}
                    justify={Number(q.source) ? "end" : "start"}
                  >
                    <Col
                      span={16}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        lineHeight: "18px",
                        width: "fit-content",
                        color: `${!Number(q.source) ? "red" : "green"}`,
                        border: `${
                          !Number(q.source)
                            ? "1px solid red"
                            : "1px solid green"
                        }`,
                        borderRadius: "10px",
                        padding: "10px",
                      }}
                    >
                      <p>{q.content}</p>
                      <span
                        style={{ textAlign: "right", justifySelf: "flex-end" }}
                      >
                        {new Date(q.date).toLocaleString()}
                      </span>
                    </Col>
                  </Row>
                ))}
            </>
          )}
        </Space>
      </Drawer>
    </>
  );
};

const Costing = () => {
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Pricing />
        </Col>
      </Row>
    </>
  );
};

export default Costing;
