import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  costable_items: [],
  costable_category: [],
  filed_items: [],
};


export const Pricing = createSlice({
  name: "pricing",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateCostableItems: (state, action) => {
      return {
        ...state,
        costable_items: [...state.costable_items, ...action.payload],
      };
    },

    updateCostableCategory: (state, action) => {
      return {
        ...state,
        costable_category: [...state.costable_category, ...action.payload],
      };
    },

    fetch_filings: async (state, payload) => {},
  },
});

export const { updateCostableItems, updateCostableCategory } = Pricing.actions;

export const selectCostableItems = (state) => state.Pricing.costable_items;
export const selectCostableCategory = (state) =>
  state.Pricing.costable_category;
export const selectFiledItems = (state) => state.Pricing.filed_items;

export default Pricing.reducer;
