import PageTitleHook from "../pagetitle";
import {
  Button,
  Input,
  notification,
  Row,
  Empty,
  Col,
  Typography,
  Avatar,
  Divider,
  Space,
  Drawer,
  Popconfirm,
} from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../assets/utils/useAuth";
import { useDispatch } from "react-redux";
import { selectclient } from "../../stores/verify";
import lib from "../../assets/utils/lib";
import "./checkins.css";
import { header, uri, uri_img } from "../../assets/utils/http-request";
import Helpers from "../../assets/utils/Helpers";

const CheckinListHook = (props) => {
  const extended = props.extended !== undefined ? props.extended : true;

  const navigate = useNavigate();
  const [checkins, setCheckins] = useState([]);
  const [checkinsCopy, setCheckinsCopy] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState("");
  const { set, user } = useAuth();
  const dispatcher = useDispatch(selectclient);

  useEffect(() => {
    if (user) {
      if (![0, 1, 2, 3].includes(Number(user.role))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: "Invalid User",
          description: "Please login with the right permission",
        });
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  });

  useEffect(() => {
    fetchCheckins();
    updateCheckins();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log('keys', filterKeyword)
    filterClaimsList(filterKeyword);
  }, [filterKeyword]); //eslint-disable-line react-hooks/exhaustive-deps

  const requestPA = (e) => {
    navigate(`/preauth?checkin=${e?.code}`, {state: e});
  };

  const filterClaimsList = (e) => {
    const inputValue = e;
    if (inputValue) {
      setCheckinsCopy(
        checkins.filter((val) => {
          return (
            val.surname.toString().toLowerCase().search(inputValue) >= 0 ||
            val.first_name.toString().toLowerCase().search(inputValue) >= 0 ||
            val.middle_name.toString().toLowerCase().search(inputValue) >= 0 ||
            val.insurance_no.toString().toLowerCase().search(inputValue) >= 0 ||
            val.code.toString().toLowerCase().search(inputValue) >= 0 ||
            val.checkin_date.toString().toLowerCase().search(inputValue) >= 0 ||
            val.checkinType.toString().toLowerCase().search(inputValue) >= 0 ||
            val.policy_no.toString().toLowerCase().search(inputValue) >= 0 ||
            val.facility_name.toString().toLowerCase().search(inputValue) >=
              0 ||
            val.facility_state.toString().toLowerCase().search(inputValue) >= 0
          );
        }),
      );
    } else {
      setCheckinsCopy(checkins);
    }
  };

  const checkOut = async (e) => {
    const pas = await lib.getPAData({ checkinId: e });
    if (Number(pas?.data["approved"]) > 0) {
      navigate(`/claims?checkin=${e}`);
    } else {
      notification.warning({
        message: "Checkout Error",
        description: "Empty Pre-Authorizations",
        duration: 2,
      });
    }
  };

  const fetchCheckins = async () => {
    return await fetch(`${uri}?checkinList`, {
      method: "post",
      body: JSON.stringify({
        facility_id: user.facility.facility_id,
        status: 0,
      }),
      headers: {
        ...header,
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const dat = data.data
          .map((c) => {
            const this_dat = {};
            Object.entries(c).forEach((col) => {
              this_dat[col[0]] = col[1] || "";
            });
            return this_dat;
          })
          .sort(
            (a, b) => Date.parse(b.checkin_date) - Date.parse(a.checkin_date),
          );

        setCheckins(dat);
        setCheckinsCopy(
          dat.filter((item) => {
            return !filterKeyword.length
              ? true
              : item?.surname.toString().toLowerCase().search(filterKeyword) >=
                  0 ||
                  item?.first_name
                    .toString()
                    .toLowerCase()
                    .search(filterKeyword) >= 0 ||
                  item?.middle_name
                    .toString()
                    .toLowerCase()
                    .search(filterKeyword) >= 0 ||
                  item?.insurance_no
                    .toString()
                    .toLowerCase()
                    .search(filterKeyword) >= 0 ||
                  item?.code.toString().toLowerCase().search(filterKeyword) >=
                    0 ||
                  item?.checkin_date
                    .toString()
                    .toLowerCase()
                    .search(filterKeyword) >= 0 ||
                  item?.checkinType
                    .toString()
                    .toLowerCase()
                    .search(filterKeyword) >= 0 ||
                  item?.policy_no
                    .toString()
                    .toLowerCase()
                    .search(filterKeyword) >= 0 ||
                  item?.facility_name
                    .toString()
                    .toLowerCase()
                    .search(filterKeyword) >= 0 ||
                  item?.facility_state
                    .toString()
                    .toLowerCase()
                    .search(filterKeyword) >= 0;
          }),
        );
      })
      .catch((e) => {
        notification.error({
          message: (
            <span>
              <strong>{e.msg ?? e.message}</strong>
              <br></br> Please make you have a good internet connection and
              <strong>refresh this page</strong>.
            </span>
          ),
        });
      });
  };

  const updateCheckins = () => {
    setTimeout(() => {
      setInterval(async () => {
        const curr = checkins.length;
        if (window.location.pathname === "/checkins") {
          await fetchCheckins();
        }
        const newCurr = checkins.length - curr;
        if (newCurr > 0) {
          notification.info({
            message: newCurr + " New Checkin(s)",
            duration: 2,
          });
        }
      }, 5000);
    }, 10000);
  };

  return (
    <>
      <PageTitleHook
        title="Encounters"
        style={{ color: "#3597d3", fontSize: "1.8em", marginTop: ".2em" }}
      />
      <table className="table align-middle mb-0 bg-white">
        <tbody>
          {extended && (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}>
                <Input
                  style={{ width: "100%" }}
                  placeholder="Enter your filter words ..."
                  value={filterKeyword}
                  onChange={(e) =>
                    setFilterKeyword(e.target.value.toLowerCase())
                  }
                />
              </td>
            </tr>
          )}
          {checkinsCopy.length ? (
            checkinsCopy.map((item) => {
              return (
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <img
                        src={`${uri_img}${item?.pic_name}`}
                        alt={item?.first_name}
                        style={{ width: "45px", height: "45px" }}
                        className="rounded-circle"
                      />
                      <div className="ms-3">
                        <p className="fw-bold mb-1">
                          {item?.surname.toUpperCase()}, {item?.first_name}{" "}
                          {extended && item?.middle_name}
                        </p>
                        {/* <p className="text-muted mb-0">{item?.email}</p> */}
                      </div>
                    </div>
                  </td>
                  {extended && (
                    <td>
                      <p className="text-muted mb-0 fw-bold">
                        {extended && "INSURANCE ID:"} {item?.insurance_no}
                      </p>
                    </td>
                  )}
                  <td className="">
                    <p className="text-muted mb-0 fw-bold">
                      {extended && "TYPE:"}{" "}
                      {item?.checkin_type_name?.toUpperCase()}
                    </p>
                    <p className="text-muted mb-0 fw-bold">
                      {extended && "DATE:"}{" "}
                      {new Date(item?.checkin_date).toLocaleString()}
                    </p>
                  </td>
                  <td>
                    <ClientDetails data={item} />
                  </td>
                  {extended && [0, 1, 2].includes(Number(user?.role)) ? (
                    <td>
                      {[0, 1].includes(Number(user?.role)) ? (
                        <Button
                          type="button"
                          className="btn btn-link btn-sm btn-rounded btn-outline-tertiary m-2"
                          onClick={() => requestPA(item)}
                        >
                          PA REQUEST
                        </Button>
                      ) : null}
                      {[0, 2].includes(Number(user?.role)) ? (
                        <Popconfirm
                          placement="top"
                          title={
                            "Are you sure you want to check this client out of your facility?"
                          }
                          onConfirm={() => checkOut(item?.code)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="button"
                            className="btn btn-link btn-sm btn-rounded  btn-outline-tertiary m-2"
                          >
                            CHECK-OUT
                          </Button>
                        </Popconfirm>
                      ) : null}
                    </td>
                  ) : null}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>
                <Empty
                  description={
                    <span>There are no chekins in this category</span>
                  }
                  style={{ padding: 50, width: "100%" }}
                >
                  {/* <Button type="primary">File a Claim Now</Button> */}
                </Empty>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

const ClientDetails = ({ data }) => {
  const [setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const contoller = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="dashed" danger onClick={contoller}>
        VIEW CLIENT
      </Button>
      <Drawer
        open={visible}
        title={
          <>
            <Typography.Title level={5} style={{ margin: "0 auto" }}>
              <Space>
                <Space>
                  <Avatar
                    src={`${uri_img}${data.pic_name}`}
                    style={{ marginRight: 20 }}
                  />
                </Space>
                <Space direction="vertical" size={"small"}>
                  <Space
                    style={{
                      margin: "0 auto",
                      padding: "0 auto",
                      height: "fit-content",
                    }}
                  >
                    {data.surname.toUpperCase().trim()},{" "}
                    {data.first_name.trim()} {data.middle_name.trim()}
                  </Space>
                  <Space
                    style={{
                      marginLeft: "0 auto",
                      fontWeight: "100",
                      fontSize: 10,
                      padding: "0 auto",
                      height: "fit-content",
                    }}
                  >
                    {data.code} - {new Date(data.checkin_date).toLocaleString()}
                  </Space>
                </Space>
              </Space>
            </Typography.Title>
          </>
        }
        onOk={handleOk}
        onClose={handleCancel}
        closable={true}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
        ]}
        width={500}
        maskClosable={true}
      >
        <Typography.Title level={5} style={{ margin: "2px auto" }}>
          Bio-Data
        </Typography.Title>
        <Divider style={{ margin: "0 auto" }} />
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Role: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.role}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Insurance Number: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.insurance_no}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Policy Number: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.policy_no}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Gender: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.gender}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>State / LGA: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.state.toUpperCase()} / {data.lga.toUpperCase()}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Primiry Health Care: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.primary_health_facility}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>
              Secondary Health Care:{" "}
            </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.secondary_health_facility}
            </Typography.Title>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default CheckinListHook;
