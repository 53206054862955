import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { IconContext } from "react-icons/lib";

import AuthProvider from "./assets/utils/useAuth";

import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./features/index/App.css";
import "antd/dist/antd.css";

import Layout from "./features/layout";
import ClientVerufyHook from "./features/clients";
import CheckinListHook from "./features/checkins";
import LoginPageHook from "./features/login";
import AuthorizationRequestHook from "./features/preauth";
import ClaimsHook from "./features/claims";
import Dash from "./features/dash";
import PAFiling from "./features/preauth/paFiling";
import ClaimFiling from "./features/claims/paFiling";
import Dashboard from "./features/dashboard";
import Costing from "./features/pricing";

const container = document.getElementById("root");
const root = createRoot(container);


root.render(
  <IconContext.Provider
    value={{ color: "blue", className: "global-class-name" }}
  >
      <AuthProvider>
        <Provider store={store}>
          <Router>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<LoginPageHook />} />
                <Route path="/login" element={<LoginPageHook />} />
                <Route path="/verify" element={<ClientVerufyHook />} />
                <Route path="/checkins" element={<CheckinListHook />} />
                <Route path="/encounters" element={<CheckinListHook />} />
                <Route path="/preauth" element={<AuthorizationRequestHook />} />
                <Route path="/preauth-action" element={<PAFiling />} />
                <Route path="/dash" element={<Dash />} />
                <Route path="/claims" element={<ClaimsHook />} />
                <Route path="/claims-action" element={<ClaimFiling />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/costing" element={<Costing />} />
                <Route path="*" element={<LoginPageHook />} />
              </Route>
            </Routes>
          </Router>
        </Provider>
      </AuthProvider>
  </IconContext.Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
