import { configureStore } from "@reduxjs/toolkit";
// import counterReducer from '../features/counter/counterSlice';
import PreAuthReducer from "../stores/preauth";
import UserReducer from "../stores/user";
import ClientReducer from "../stores/verify";
import ClaimsReducer from "../stores/Claims";
import Queries from "../stores/queries";
import Pricing from "../stores/pricing";

export const store = configureStore({
  reducer: {
    preAuth: PreAuthReducer,
    userAuth: UserReducer,
    ClientStore: ClientReducer,
    claims: ClaimsReducer,
    QueriesStore: Queries,
    PricingStore: Pricing,
  },
});
