import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Empty,
  Divider,
  Statistic,
  Card,
  Drawer,
  DatePicker,
} from "antd";
import "./index.css";
import { myRequest } from "../../assets/utils/lib";
import CountUp from "react-countup";
import { Dna } from "react-loader-spinner";
import CheckinListHook from "../checkins";
import ClaimsHook from "../claims";

const formatter = (value) => <CountUp end={value} separator="," />;
const { RangePicker } = DatePicker;

const PlansSummary = () => {
  const { pathname } = useLocation();
  const [summary, setSummary] = useState({});
  const [filter, setFilter] = useState({});
  const [filterDate, setFilterDate] = useState({ start: null, end: null });
  const [loadWithFilter, setLoadWithFilter] = useState(false);
  const [filterReste, setresetFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showClaimsPendingPayment, setShowClaimsPendingPayment] = useState(false);

  // Statistically Related States
  const [showAllClaims, setShowAllClaims] = useState(false);
  const [showDeclinedClaims, setShowDeclinedClaims] = useState(false);
  const [showPendingClaims, setShowPendingClaims] = useState(false);
  const [showPaidClaims, setShowPaidClaims] = useState(false);
  const [showCheckind, setShowCheckind] = useState(false);

  const timerId = useRef();

  useEffect(() => {
    if (pathname !== "/dashboard") clearInterval(timerId);
  }, [pathname]);

  useEffect(() => {
    if (loadWithFilter) {
      clearInterval(timerId);
      getSummary(true);
      timerId.current = setInterval(() => {
        getSummary(false);
      }, 5000);
    }
    return () => clearInterval(timerId.current);
  }, [loadWithFilter, filter]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterReste) {
      clearInterval(timerId);
      getSummary(true);
      timerId.current = setInterval(() => {
        getSummary(false);
      }, 5000);
    }
    setresetFilter(false);
    return () => clearInterval(timerId.current);
  }, [filterReste]); //eslint-disable-line react-hooks/exhaustive-deps

  const getSummary = (first = false) => {
    if (first) setLoading(true);
    let filtered = { extended: true };

    if (loadWithFilter && Object.keys(filter).length) {
      filtered = { ...filtered, filter };

      if (filter["date"]?.length) {
        if (filter["date"][0] && filter["date"][1]) {
          filtered["filter"]["date"] = {
            start: filter["date"][0].format("YYYY-MM-DD HH:mm:ss"),
            end: filter["date"][1].format("YYYY-MM-DD HH:mm:ss"),
          };
        }
      }
    }

    myRequest("plansSummaryFacilty").post(filtered)
      .then((resp) => resp?.data)
      .then((planTypes) => {
        if (planTypes && !planTypes?.error && !planTypes?.data?.length)
          setSummary(planTypes?.data);
      })
      .finally(() => {
        if (first) setLoading(false);
      });
  };

  useEffect(() => {
    getSummary(true);
    timerId.current = setInterval(() => {
      getSummary(false);
    }, 5000);
    return () => clearInterval(timerId.current);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  // Finanically Related
    setShowClaimsPendingPayment(!!!showClaimsPendingPayment);

  // Statistically Related
  const showAllClaimDrawer = () => setShowAllClaims(!!!showAllClaims);
  const showDeclinedClaimDrawer = () =>
    setShowDeclinedClaims(!!!showDeclinedClaims);
  const showPendingClaim = () => setShowPendingClaims(!!!showPendingClaims);
  const showPaidClaimDrawer = () => setShowPaidClaims(!!!showPaidClaims);
  const showCheckedClients = () => setShowCheckind(!!!showCheckind);

  const onChange = (date) => {
    setFilter((d) => ({ ...d, date: date }));
    setFilterDate({ start: date[0], end: date[1] });
  };

  const refreshSummary = () => {
    setLoadWithFilter(true);
    setresetFilter(false);
  };

  const resetFilter = () => {
    setFilter({ data: [null, null] });
    setLoadWithFilter(false);
    setresetFilter(true);
  };

  return (
    <div style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}>
      <Row gutter={[12, 12]}>
        <Col md={4} sm={4} lg={4}>
          Filters:
        </Col>
        <Col span={6} md={6} sm={24} lg={5}>
          <RangePicker
            picker="day"
            onChange={onChange}
            style={{ width: "100%" }}
            value={[filterDate.start, filterDate.end]}
          />
        </Col>
        <Col span={3} md={3} sm={12} lg={4}>
          <Button
            style={{ width: "100%" }}
            onClick={refreshSummary}
            disabled={loadWithFilter}
          >
            Reload With Filter
          </Button>
        </Col>
        <Col span={3} md={3} sm={12} lg={4}>
          <Button
            style={{ width: "100%" }}
            onClick={resetFilter}
            disabled={!loadWithFilter}
          >
            Clear Filter
          </Button>
        </Col>
      </Row>
      {!loading ? (
        Object.keys(summary).length ? (
          <>
            <Divider
              style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
              orientationMargin={0}
              orientation="left"
            >
              Finances
            </Divider>
            <Row gutter={[12, 12]} justify={"stretch"}>
              <Col md={8} sm={12} lg={12} style={{ cursor: "pointer" }}>
                <Card bordered={false} onClick={showAllClaimDrawer}>
                  <Statistic
                    prefix="&#8358;"
                    title={<strong>Total Claims</strong>}
                    value={summary?.totalClaimsCost}
                    precision={2}
                    formatter={formatter}
                  />
                </Card>
              </Col>
              <Col md={8} sm={12} lg={12} style={{ cursor: "pointer" }}>
                <Card bordered={false} onClick={showPaidClaimDrawer} hoverable>
                  <Statistic
                    prefix="&#8358;"
                    title={<strong>Claims Paid</strong>}
                    value={summary?.totalClaimsPaidCost}
                    precision={2}
                    formatter={formatter}
                  />
                </Card>
              </Col>
              <Col md={8} sm={12} lg={12} style={{ cursor: "pointer" }}>
                <Card bordered={false} onClick={showPendingClaim} hoverable>
                  <Statistic
                    prefix="&#8358;"
                    title={<strong>Pending Payment</strong>}
                    value={summary?.totalClaimsPendingCost}
                    precision={2}
                    formatter={formatter}
                  />
                </Card>
              </Col>
              <Col md={8} sm={12} lg={12} style={{ cursor: "pointer" }}>
                <Card
                  bordered={false}
                  onClick={showDeclinedClaimDrawer}
                  hoverable
                >
                  <Statistic
                    prefix="&#8358;"
                    title={<strong>Rejected Claimed Items</strong>}
                    value={summary?.totalClaimsRejected}
                    precision={2}
                    formatter={formatter}
                  />
                </Card>
              </Col>
            </Row>
            <Divider
              style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
              orientationMargin={0}
              orientation="left"
            >
              Statistics
            </Divider>
            <Row gutter={[12, 12]} justify={"stretch"}>
              <Col md={8} sm={12} lg={8} style={{ cursor: "pointer" }}>
                <Card bordered={false}>
                  <Statistic
                    title={<strong>Total Claims Filed</strong>}
                    value={summary?.totalClaimsFiled}
                    precision={2}
                    formatter={formatter}
                  />
                </Card>
              </Col>
              <Col md={8} sm={12} lg={8} style={{ cursor: "pointer" }}>
                <Card bordered={false}>
                  <Statistic
                    title={<strong>Total Pending Claims</strong>}
                    value={summary?.totalPendingCLaims}
                    precision={2}
                    formatter={formatter}
                  />
                </Card>
              </Col>
              <Col md={8} sm={12} lg={8} style={{ cursor: "pointer" }}>
                <Card bordered={false}>
                  <Statistic
                    title={<strong>Total Paid Claims</strong>}
                    value={summary?.totalPaidClaims}
                    precision={2}
                    formatter={formatter}
                  />
                </Card>
              </Col>
              <Col md={8} sm={12} lg={8} style={{ cursor: "pointer" }}>
                <Card
                  bordered={false}
                  actions={[
                    <>In-Patients: {summary?.totalCheckinsIn}</>,
                    <>Out-Patients: {summary?.totalCheckinsOut}</>,
                  ]}
                >
                  <Statistic
                    title={<strong>Total Check-Ins</strong>}
                    value={summary?.totalCheckins}
                    precision={2}
                    formatter={formatter}
                  />
                </Card>
              </Col>
              <Col md={8} sm={12} lg={8} style={{ cursor: "pointer" }}>
                <Card
                  bordered={false}
                  onClick={showCheckedClients}
                  hoverable
                  actions={[
                    <>In-Patients: {summary?.activeCheckinsIn}</>,
                    <>Out-Patients: {summary?.activeCheckinsOut}</>,
                  ]}
                >
                  <Statistic
                    title={<strong>Active Check-Ins</strong>}
                    value={summary?.activeCheckins}
                    precision={2}
                    formatter={formatter}
                  />
                </Card>
              </Col>
            </Row>
          </>
        ) : (
          <Empty
            description={"Summary is not available"}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )
      ) : (
        <Dna
          visible={true}
          height="100"
          width="100"
          ariaLabel="dna-loading"
          wrapperStyle={{ position: "relative", top: "40%", left: "40%" }}
          wrapperClass="dna-wrapper"
        />
      )}

      <Drawer
        title="All Claims"
        width={720}
        closable={false}
        onClose={showAllClaimDrawer}
        open={showAllClaims}
      >
        <ClaimsHook extended={false} section="all" />
      </Drawer>

      <Drawer
        title="Pending Claims"
        width={720}
        closable={false}
        onClose={showPendingClaim}
        open={showPendingClaims}
      >
        <ClaimsHook extended={false} section="pending" />
      </Drawer>

      <Drawer
        title="Claims - Paid"
        width={720}
        closable={false}
        onClose={showPaidClaimDrawer}
        open={showPaidClaims}
      >
        <ClaimsHook extended={false} section="paid" />
      </Drawer>

      <Drawer
        title="Claims - Declined Payment"
        width={720}
        closable={false}
        onClose={showDeclinedClaimDrawer}
        open={showDeclinedClaims}
      >
        <ClaimsHook extended={false} section="declined" />
      </Drawer>

      <Drawer
        width={720}
        closable={false}
        onClose={showCheckedClients}
        open={showCheckind}
      >
        <CheckinListHook extended={false} />
      </Drawer>
    </div>
  );
};

const Dashboard = () => {

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <PlansSummary />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
