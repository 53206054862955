import { Button, Divider, Input, message, notification, Select } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../assets/utils/useAuth";
import useLocalStorage from "../../assets/utils/useLocal";
import { useDispatch } from "react-redux";
import lib, { fetchPaginatedData } from "../../assets/utils/lib";
import "./clients.css";
import { header, uri, uri_img } from "../../assets/utils/http-request";
import { add_client, selectclient } from "../../stores/verify";
import { add_checkin, selectChechin } from "../../stores/verify/checkin";
import config from "../../assets/utils/config";
import Helpers from "../../assets/utils/Helpers";
import logo from "../../assets/images/claima.png"
import PopConfirm from "../compoinents/PopConfirm";

const { Option } = Select;

const ClientVerifyHook = (props) => {
  const dispatcher = useDispatch(selectclient);
  const { set, user } = useAuth();
  const [showDetailFlag, setShowDetailFlag] = useState(false);

  const [client, setClient] = useState({});

  const [clientDate, setClientData] = useLocalStorage(config.key.client);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (![0, 1, 2, 3].includes(Number(user.role))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: "Invalid User",
          description: "Please login with the right permission",
        });
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  });

  const showDetail = async () => {
    setShowDetailFlag(false);
    setClientData({});

    const key = "verify";

    if (!Object.keys(client).length) return;

    message.loading({ content: "Loading...", key });

    let reqData = await lib.getClient(client);

    if (reqData?.error === 1) {
      message.error({ content: reqData.message, key, duration: 3 });
    }

    if (reqData?.error === 0) {
      setShowDetailFlag(true);
      notification.success({ message: reqData?.message, key, duration: 2 });
      dispatcher(add_client(reqData?.data));
      setClientData(reqData?.data);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      showDetail()
      return;
    }
  };

  return (
    <>
      {!user || ![0, 1, 2, 3].includes(Number(user.role)) ? (
        <></>
      ) : (
        <div className="loginPage App-header">
          <div>
            <label for="form7Example1" class="form-label">
              Enter Client's Insurance No.
            </label>
            <div class="input-group mb-4 gap-3">
              <Input
                id="form7Example1"
                class="form-control rounded"
                onChange={(e) => setClient({ insurance_no: e.target.value })}
                onKeyDown={handleKeyPress}
              />
              <Button
                type="primary"
                onClick={showDetail}
                style={{ margin: "0 auto", width: 200 }}
              >
                Verify
              </Button>
            </div>
          </div>
          <div>
            {showDetailFlag ? <ClientDataHook {...clientDate} /> : null}
          </div>
        </div>
      )}
    </>
  );
};

export const ClientDataHook = (props) => {
  const dispatcher = useDispatch(selectChechin);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [, setShowDetailFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkinTypes, setCheckinTypes] = useState([]);
  const [loadingCareCategory, setLoadingCareCategory] = useState(false);

  async function fetchCareTypesList(payload = {}) {
    setLoadingCareCategory(true);
    const $allData = [];
    try {
      const generator = fetchPaginatedData("post", "careTypesList", payload);

      for await (const data of generator) {
        $allData.push(...data);
      }
      setCheckinTypes(
        $allData.map((item) => ({
          label: item?.checkin_type_name?.toUpperCase(),
          value: item?.checkin_type_id,
        }))
      );
    } catch (error) {
      console.error("Error fetching care categories:", error);
    } finally {
      setLoadingCareCategory(false);
      return $allData;
    }
  }

  useState(fetchCareTypesList, []);

  const style = {
    display: "flex",
    color: "blue",
    fontSize: ".6em",
    textAlign: "left",
    padding: ".2rem",
    borderWidth: ".2em",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, .4)",
    borderRadius: ".5em",
  };

  // const options = [
  //   { value: "1", key: "In-Patient" },
  //   { value: "2", key: "Out-Patient" },
  //   { value: "3", key: "Antenatal" },
  // ];

  const [checkinType, setCheckinType] = useState({
    insurance_no: props.insurance_no,
    user_id: props.id,
    facility_id: user.facility.facility_id,
  });

  const verifyCheckin = async () => {
    setLoading(true);

    if (Object.keys(checkinType).length < 4) return;

    const key = "verifyCheck";

    const request = await fetch(`${uri}?checkCheckin`, {
      method: "POST",
      body: JSON.stringify(checkinType),
      headers: {
        ...header,
        Authorization: `Bearer ${user.jwt}`,
      },
    });

    const response = await request?.json();

    switch (response.error) {
      case 1:
        notification.warning({
          message: "Duplicate Checkin Alert!",
          description: (
            <span>
              {response.message}. <br></br>Are you sure want to proceed as
              out-patient?
            </span>
          ),
          btn: (
            <>
              <Button
                type="primary"
                danger
                onClick={() => {
                  notification.close(key);
                  checkin();
                }}
              >
                Confirm
              </Button>
              <Divider type="vertical" />
              <Button
                type="primary"
                danger
                onClick={() => notification.close(key)}
              >
                Cancle
              </Button>
            </>
          ),
          key,
        });
        return;

      case 2:
        notification.warning({
          message: "Duplicate Checkin Alert!",
          description: (
            <span>
              {response.message}. <br></br>Are you sure want to proceed as
              in-patient?
            </span>
          ),
          btn: (
            <>
              <Button
                type="primary"
                danger
                onClick={() => {
                  notification.close(key);
                  checkin();
                }}
              >
                Confirm
              </Button>
              <Divider type="vertical" />
              <Button
                type="primary"
                danger
                onClick={() => notification.close(key)}
              >
                Cancle
              </Button>
            </>
          ),
          key,
        });
        return;

      case 3:
        notification.error({
          message: "Duplicate Checkin Alert!",
          description: <span>{response.message}.</span>,
          key,
          duration: 2,
        });
        return;

      default:
        checkin();
    }
  };

  const checkin = async () => {
    const key = "verifyCheck";

    message.loading({ content: "Loading...", key });

    let reqData = await lib.checkin(checkinType);

    if (reqData?.error === 1) {
      message.error({ content: reqData.message, key, duration: 3 });
    }

    if (reqData?.error === 0) {
      setShowDetailFlag(true);
      message.success({ content: reqData.message, key, duration: 2 });
      dispatcher(add_checkin(checkinType));
      setTimeout(() => {
        navigate("/checkins");
      }, 200);
    }

    setLoading(true);
  };

  const promptMessages = {
    1: 'Are you certain this client is getting admitted?',
    2: 'Are you certain this client is her for out-patient services?',
    3: 'Are you certain this patient is here for antenatal service?',
    4: 'Are you sure this patient is here for dental care?',
    5: 'Are you sure this patient is here for eye care?'
  }

  return (
    <>
      <div className="card-c" style={style}>
        <div className="img-round img-medium">
          <img
            src={props.pic_name ? `${uri_img}${props.pic_name}` : logo}
            alt=""
            style={{
              width: "120px",
              height: "120px",
              display: "flex",
              alignSelf: "center",
              justifySelf: "center",
              margin: ".3em",
            }}
            className="rounded-circle"
          />
        </div>
        <div
          className="card-c-details"
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            justifySelf: "center",
          }}
        >
          <div className="card-c-datarow">
            <span className="card-c-label">Patient Full Name</span>:
            <span className="card-c-value">
              {" "}
              {props.surname.toUpperCase()}, {props.first_name}{" "}
              {props.middle_name}
            </span>
          </div>
          <div className="card-c-datarow">
            <span className="card-c-label">Patient Insurance ID</span>:
            <span className="card-c-value"> {props.insurance_no}</span>
          </div>
          <div className="card-c-datarow">
            <span className="card-c-label">Patient Premium Type</span>:
            <span className="card-c-value">
              {" "}
              {/* {props.insurance_package} | {props.premium_type} */}
              {props.premium_type}
            </span>
          </div>
          <div className="card-c-datarow">
            <span className="card-c-label">Patient Premium Status</span>:
            <span className="card-c-value">
              {" "}
              {props.status ? `Active` : "Expired"}
            </span>
          </div>
        </div>
      </div>
      {parseInt(props.status) && [0, 1].includes(Number(user.role)) ? (
        <div style={{ marginTop: ".3rem", color: "blue", fontSize: ".6em" }}>
          <div className="input-group">
            <Select
              showSearch
              placeholder="Select Clinic Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA?.children?.toLowerCase()?.localeCompare(optionB?.children?.toLowerCase())
              }
              style={{ width: "calc(100% - 144.43px" }}
              onChange={(e) =>
                setCheckinType((d) => ({ ...d, checkinType: e }))
              }
              options={checkinTypes}
              disabled={loadingCareCategory}
            />
            {/* {checkinTypes.map((item) => {
                return <Option value={item.value}>{item.key}</Option>;
              })} */}
            {/* </Select> */}
            <PopConfirm
              title={promptMessages[Number(checkinType['checkinType'])]}
              onConfirm={verifyCheckin}
              triggerButtonProps={{ disabled: loading, type: 'default', style: { width: '100%' } }}
              triggerButtonText={'Check Client In'}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ClientVerifyHook;
