import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  client: {},
};

export const Appclient = createSlice({
  name: "appclient",
  initialState,
  reducers: {
    add_client: (state, action) => {
      state.client = action.payload;
    },
    remove_client: (state) => {
      state.client = {};
    },
  },
});

export const { add_client, remove_client } = Appclient.actions;

export const selectclient = (state) => state.Appclient.client;

export default Appclient.reducer;
