import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Select,
  Button,
  Input,
  Spin,
  Row,
  Col,
  Drawer,
  Typography,
  Space,
  Empty,
  Modal,
  Badge,
  Divider,
} from "antd";
import { TiDocumentText } from "react-icons/ti";
import PageTitleHook from "../pagetitle";
import { uri_img } from "../../assets/utils/http-request";
import { myRequest, myRequestObj } from "../../assets/utils/lib";
import { add_queries } from "../../stores/queries";
import logo from "../../assets/images/claima.png";

import "./preauth.css";
import { MessageOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const PARequestHook = () => {
  const [items, setItems] = useState([]);
  const [provDiagenosis, setProvDiagenosis] = useState([]);
  const [client, setClient] = useState({});
  const navigator = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const itemStatusesKeys = ["Pending", "Approved", "Queried", "Declined"];

  const interval = useRef();

  useEffect(() => {
    fetchPAData(params.get("checkin"))
      .then((res) => {
        setItems(
          res?.items?.filter((item) =>
            !params.get("filter")
              ? true
              : itemStatusesKeys.indexOf(params.get("filter")) ===
                Number(item?.claim_item_status),
          ),
        );
        setClient(res?.misc);
        updateList();
      })
      .finally();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  async function fetchPAData(claimsId) {
    return myRequestObj(`paList`)
      .post({ checkinId: claimsId })
      .then((response) => response?.data)
      .then((body) => {
        const provList = body?.data?.diagnosis?.map((d) => ({
          label: `${d?.code} ${d?.name}`,
          value: d?.id,
        }));
        setProvDiagenosis(provList);
        return body?.data;
      });
  }

  const send_claim = async () => {
    navigator("/preauth");
  };

  const updateList = () => {
    if (interval.current) clearInterval(interval.current);
    interval.current = setInterval(() => {
      if (window.location.pathname === "/preauth-action") {
        fetchPAData(params.get("checkin")).then((res) => {
          setItems(
            res.items.filter((item) =>
              !params.get("filter")
                ? true
                : itemStatusesKeys.indexOf(params.get("filter")) ===
                  Number(item?.claim_item_status),
            ),
          );
          setClient(res.misc);
        });
      }
    }, 3000);
  };

  return (
    <>
      <PageTitleHook
        title={`Compose Claim Request`}
        style={{ fontSize: "1.8em", marginTop: "1em", marginBottom: "1em" }}
      />
      <hr />
      <ClientDataHook data={client} style={{ maxWidth: 750 }} />
      <div style={{ maxHeight: "450px", overflowY: "auto" }}>
        <label className="form-label">Diagnosis - Provisional</label>
        <Select
          mode="multiple"
          value={provDiagenosis}
          style={{ width: "100%" }}
          disabled
        ></Select>
      </div>
      <div style={{ marginTop: 10 }}>
        <table className="table align-middle mb-0 bg-white">
          <thead>
            <tr style={{ fontWeight: "bolder" }}>
              <td>S/N</td>
              <td>Authorization Code</td>
              <td>Care Category</td>
              <td>Request Type</td>
              <td>Requested Item</td>
              <td>Quantity</td>
              <td>Filed Cost</td>
              <td>Approved Cost</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              return (
                <AuthorizationRequestDataListHook data={item} index={index} />
              );
            })}
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          margin: "30px",
          justifyContent: "flex-end",
          gap: "5em",
        }}
      >
        <Button onClick={send_claim}>Close </Button>
      </div>
    </>
  );
};

const AuthorizationRequestDataListHook = (props) => {
  
  const [itemsQueryStates, ] = useState({
    itemId: props?.data?.id_,
    status: 0,
  });
  const [itemQueryReqStatus, ] = useState(false);
  
  const [itemRejectReqStatus, ] = useState(false);

  const authorize = () => {
    if (Number(props?.data?.claim_item_status) === 1) {
      Modal.success({
        title: "Approval Memo",
        content: (
          <div style={{ marginTop: "10px", maxHeight: 400, overflowY: "auto" }}>
            {props?.data?.comment}{" "}
          </div>
        ),
        width: 800,
      });
    } else {
      Modal.error({
        title: "Rejection Memo",
        content: (
          <div style={{ marginTop: "10px", maxHeight: 400, overflowY: "auto" }}>
            {props?.data?.comment}{" "}
          </div>
        ),
        width: 800,
      });
    }
  };

  return (
    <>
      <tr>
        <td className="fw-bold">{props?.index + 1}</td>
        <td className="fw-bold">
          <span>
            <strong>
              {Array.from(props?.data?.auth_code ?? "")
                .filter((i) => i !== ".")
                .join("")}
            </strong>
          </span>
        </td>
        <td className="fw-bold">
          <span>
            <strong>{props?.data?.plans_items_name}</strong>
          </span>
        </td>
        <td className="fw-bold">
          <span>
            <strong>{props?.data?.category_name.split(" ")[0]}</strong>
          </span>
        </td>
        <td className="fw-bold">
          <span>
            <strong>{props?.data?.item_name}</strong>
          </span>
        </td>
        <td>
          <span>
            <strong>{props?.data?.quantity}</strong>
          </span>
        </td>
        <td>
          <span>
            <strong>{props?.data?.cost}</strong>
          </span>
        </td>
        <td>
          <span style={{ width: 100 }} className="fw-normal mb-1 fw-bold">
            {props?.data?.approved_cost}
          </span>
        </td>
        <td>
          <Space split={<Divider orientation="vertical" />}>
            {Number(props?.data?.claim_item_status) === 1 ? (
              <Button
                type={
                  Number(props?.data?.claim_item_status) === 3
                    ? "danger"
                    : Number(props?.data?.claim_item_status) === 2
                    ? "dashed"
                    : Number(props?.data?.claim_item_status) === 1
                    ? "primary"
                    : "default"
                }
                disabled={
                  !Number(props?.data?.claim_item_status) ||
                  !Number(props?.data?.claim_item_status) === 3
                }
                onClick={
                  Number(props?.data?.claim_item_status) !== 2 ? authorize : null
                }
              >
                {" "}
                <span>
                  {" "}
                  <TiDocumentText color="white" /> Authourized
                </span>
              </Button>
            ) : Number(props?.data?.claim_item_status) === 3 ? (
              <Button
                type={
                  Number(props?.data?.claim_item_status) === 3
                    ? "danger"
                    : Number(props?.data?.claim_item_status) === 2
                    ? "dashed"
                    : Number(props?.data?.claim_item_status) === 1
                    ? "primary"
                    : "default"
                }
                disabled={
                  !Number(props?.data?.claim_item_status) ||
                  !Number(props?.data?.claim_item_status) === 3
                }
                onClick={
                  Number(props?.data?.claim_item_status) !== 2 ? authorize : null
                }
              >
                <span>
                  {" "}
                  <TiDocumentText color="white" /> Rejected
                </span>
              </Button>
            ) : (
              <Button
                type={
                  Number(props?.data?.claim_item_status) === 3
                    ? "danger"
                    : Number(props?.data?.claim_item_status) === 2
                    ? "dashed"
                    : Number(props?.data?.claim_item_status) === 1
                    ? "primary"
                    : "default"
                }
                disabled={
                  !Number(props?.data?.claim_item_status) ||
                  !Number(props?.data?.claim_item_status) === 3
                }
                onClick={
                  Number(props?.data?.claim_item_status) !== 2 ? authorize : null
                }
              >
                Pending
              </Button>
            )}
            <PAQuery
              data={props.data}
              itemQueryReqStatus={itemQueryReqStatus}
              itemRejectReqStatus={itemRejectReqStatus}
              itemsQueryStates={itemsQueryStates}
            />
          </Space>
        </td>
      </tr>
    </>
  );
};

const PAQuery = ({
  data,
  itemQueryReqStatus,
  itemRejectReqStatus,
  itemsQueryStates,
}) => {
  const dispatcher = useDispatch();

  const queries = useSelector((state) => state.QueriesStore.messages)
    .filter((i) => i.marker == data?.id_ && Number(i.level) == 0) //eslint-disable-line
    .sort((a, b) => Number(a.id) - Number(b.id));

  const [loading, setLoading] = useState(false);
  const [sending, setsending] = useState(false);
  const [visible, setVisible] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [messageData, ] = useState({
    relation: "claim_items",
    marker: data?.id_,
    source: 1,
  });
  const [, setFirstLoad] = useState(true);
  const [unreadQueriesCount, setUnreadQueriesCount] = useState(0);
  const [reading, ] = useState(false);
  const [queried, setQueried] = useState(0);

  let interval = useRef();
  let interval2 = useRef();

  useEffect(() => {
    const payload = { id_: data?.id_, source: 0, level: 0 };
    myRequest("post", "queriesCountPA", payload)
      .then((res) => res.data)
      .then((body) => {
        if (!body?.error) {
          setUnreadQueriesCount(body?.data[0]?.queris);
          setQueried(Number(body?.data[1]?.querid));
        }
      })
      .finally(() => {
        if (interval2.current) clearInterval(interval2.current);
        interval2.current = setInterval(() => {
          if (!reading && window.location.pathname === "/preauth-action") {
            myRequest("post", "queriesCountPA", payload)
              .then((res) => res.data)
              .then((body) => {
                if (!body.error) {
                  setUnreadQueriesCount(body?.data[0]?.queris);
                  setQueried(Number(body?.data[1]?.querid));
                }
              });
          }
        }, 4000);
      });
    return () => clearInterval(interval2.current);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const queriesToUpdate = queries
      .filter((j) => !Number(j?.status) && !Number(j?.source))
      .map((i) => {
        return {
          id: i?.id,
        };
      });

    if (visible && queriesToUpdate.length) {
      myRequest("post", "update_queries", queriesToUpdate)
        .then((res) => res.data)
        .catch((e) => console.log(e.msg ?? e.message))
        .finally(setFirstLoad(false));
    }
  }, [visible, queries]);

  useEffect(() => {
    setLoading(true);
    fetchQueries(visible).then((res) => {
      setLoading(false);
      updateQueries();
    });
  }, [visible]); //eslint-disable-line

  const handleOk = () => {
    setsending(true);
    const payload = { content: messageText, ...messageData };
    myRequest("post", "query", payload)
      .then((res) => res.data)
      .then((body) => {
        setsending(false);
        setMessageText("");
      });
  };

  const handleCancel = () => {
    clearInterval(interval.current);
    setVisible(false);
  };

  const contoller = () => {
    setVisible(true);
  };

  const composeMessage = (e) => {
    const mess = e.target.value;
    setMessageText(mess);
  };

  const fetchQueries = async (x) => {
    if (!x) return;
    return myRequest("post", "queries", messageData)
      .then((res) => res.data)
      .then((body) => {
        if (!body?.error && body?.data?.length) {
          dispatcher(add_queries(body.data));
        }
      });
  };

  const updateQueries = () => {
    if (interval.current) clearInterval(interval.current);
    interval.current = setInterval(() => {
      fetchQueries(visible);
    }, 5000);
  };

  return (
    <>
      {
        queried ? (
          <>
            <Badge count={Number(unreadQueriesCount) || null}>
              <MessageOutlined
                style={{
                  fontSize: "2em",
                  fontWeight: "bolder",
                  color:
                    !Number(data?.claim_item_status) ||
                    Number(data?.claim_item_status) === 2 ||
                    queried
                      ? "#ff7875"
                      : "grey",
                }}
                onClick={() =>
                  !Number(data?.claim_item_status) ||
                  Number(data?.claim_item_status) === 2 ||
                  queried
                    ? contoller()
                    : null
                }
                disabled={
                  !Number(data?.claim_item_status) ||
                  Number(data?.claim_item_status) === 2 ||
                  queried
                    ? false
                    : true
                }
                title="Query"
              />
            </Badge>
            <Drawer
              visible={visible}
              title={
                <>
                  <Typography.Title level={5} style={{ margin: "0 auto" }}>
                    Query: {data?.checkin_id}
                  </Typography.Title>
                </>
              }
              onClose={handleCancel}
              footer={[
                <Row justify="space-between" align="bottom">
                  <Col span={20}>
                    <TextArea
                      rows={2}
                      value={messageText}
                      placeholder="Write Full Description of the Query...."
                      onChange={composeMessage}
                      id={"message"}
                      disabled={
                        [1, 3].includes(Number(data?.claim_item_status))
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      key="back"
                      onClick={handleOk}
                      style={{ color: "skyblue", fontSize: "1em" }}
                      disabled={
                        [1, 3].includes(Number(data?.claim_item_status)) ||
                        sending ||
                        loading ||
                        !messageText.length
                          ? true
                          : false
                      }
                    >
                      {" "}
                      Send{" "}
                    </Button>
                  </Col>
                </Row>,
              ]}
              width={600}
            >
              <Space
                gutter={[8, 16]}
                direction={"vertical"}
                id="chatContainer"
                style={{ width: "100%" }}
              >
                {loading ? (
                  <Col>
                    <Spin size="large" />
                  </Col>
                ) : !queries.length ? (
                  <Empty description={<span>No Queries</span>} />
                ) : (
                  <>
                    {queries
                      .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
                      .map((q, i) => (
                        <Row
                          id={`query_${i}`}
                          justify={!Number(q?.source) ? "end" : "star"}
                        >
                          <Col
                            span={16}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              lineHeight: "18px",
                              width: "fit-content",
                              color: `${!Number(q?.source) ? "red" : "green"}`,
                              border: `${
                                !Number(q?.source)
                                  ? "1px solid red"
                                  : "1px solid green"
                              }`,
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <p>{q?.content}</p>
                            <span
                              style={{
                                textAlign: "right",
                                justifySelf: "flex-end",
                              }}
                            >
                              {new Date(q?.date).toLocaleString()}
                            </span>
                          </Col>
                        </Row>
                      ))}
                  </>
                )}
              </Space>
            </Drawer>
          </>
        ) : null
      }
    </>
  );
};

const ClientDataHook = (props) => {
  const style = {
    ...props.style,
    display: "flex",
    color: "black",
    fontSize: "1.3em",
    textAlign: "left",
    padding: ".2rem",
    borderWidth: ".2em",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, .4)",
    borderRadius: ".5em",
  };

  return (
    <>
      <Row className="card-c" style={style} align="middle">
        <Col span={4} className="img-round img-medium">
          <img
            src={
              props?.data?.pic_name ? `${uri_img}${props?.data?.pic_name}` : logo
            }
            alt=""
            style={{
              width: "120px",
              height: "120px",
              display: "flex",
              alignSelf: "center",
              justifySelf: "center",
              margin: ".3em",
            }}
            className="rounded-circle"
          />
        </Col>
        <Col
          span={20}
          className="card-c-details"
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            justifySelf: "center",
            paddingLeft: "30px",
          }}
        >
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Full Name:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props?.data?.surname ? props?.data?.surname.toUpperCase() : ""},{" "}
              {props?.data?.first_name ? props?.data?.first_name : ""}{" "}
              {props?.data?.middle_name ? props?.data?.middle_name : ""}
            </Col>
          </Row>
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Insurance ID:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props?.data?.insurance_no ? props?.data?.insurance_no : ""}
            </Col>
          </Row>
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Premium Type:
            </Col>
            <Col className="card-c-value">{props?.data?.premium_type ? props?.data?.premium_type : ""}</Col>
          </Row>
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Premium Status:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props?.data?.status ? `Active` : "Expired"}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const PAFiling = () => {
  let params = useRef(new URLSearchParams(window.location.search));

  useEffect(() => {
    params.current = new URLSearchParams(window.location.search);
  }, [window.location.search]); //eslint-disable-line

  return (
    <>{params.current.get("checkin") ? <PARequestHook /> : navigator("/preauth")}</>
  );
};

export default PAFiling;
