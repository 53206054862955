//

const config = {
  pages: {
    overview: "/overview",
    pricing: "/pricing",
    login: "/login",
    // logout: '/logout',
    register: "/register",
    reset: "/reset",
    predashboard: "/predashboard",
  },
  key: {
    user: "___@aman_user",
    token: "___@aman_token",
    paymentStatus: "paymentStatus",
    client: "___@verify",
    q_count: "f_q_count",
    q_reading: "f_q_reading",
  },
  paymentApi: {
    public: "FLWPUBK-2ff657dbfa82fab6353bac0c95151392-X",
  },
};

export default config;
