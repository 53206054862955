import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Select,
  Button,
  Input,
  message,
  Row,
  Col,
  Empty,
  Modal,
  notification,
  // Menu,
  Switch,
  Typography,
  Divider,
  Spin,
  Form,
} from "antd";
// import { UserOutlined } from "@ant-design/icons";
import { useAuth } from "../../assets/utils/useAuth";
import Helpers from "../../assets/utils/Helpers";

import "./index.css";
import lib, { myRequestObj } from "../../assets/utils/lib";

const ClaimsRequestHook = (props) => {
  const { user } = useAuth();
  const [facilityUsers, setFacilityUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [passwordResetForm, showPasswordResetForm] = useState(false);
  const [targetUser, setTargetUser] = useState(null);

  const interval = useRef();

  useEffect(() => {
    setLoading(true);

    loadUsers()
      .then((data) => {
        setFacilityUsers(data);
      })
      .finally(() => {
        setLoading(false);
        if (interval.current) clearInterval(interval.current);
        interval.current = setInterval(() => {
          loadUsers().then((data) => {
            setFacilityUsers(data);
          });
        }, 5000);
      });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const loadUsers = async () => {

    return (await lib.readFacilityUser({ id: user.facility.facility_id }))
      ?.data;
  };

  const updateSuerState = async (e, u) => {
    const payload = {
      uid: u,
      id: user.facility.facility_id,
      payload: Number(e),
    };

    notification.info({
      message: "Updating Profile",
      key: 1,
      duration: 2,
    });

    const data = await lib.updateProfile(payload);
    if (data) {
      if (data.error) {
        notification.error({
          message: data.message,
          description: data.description,
          duration: 3,
          key: 1,
        });
        return;
      }

      notification.success({
        message: data.message,
        description: data.description,
        duration: 3,
        key: 1,
      });
      loadUsers().then((data) => {
        setFacilityUsers(data);
      });
    }
  };

  const handlePaswordResetForm = (targetUser) => {
    setTargetUser(targetUser);
    setTimeout(() => showPasswordResetForm(true), 500)
  }

  const handlePasswordReset = (data) => {
    setLoadingReset(true)
    data = {...data, ...targetUser};
    myRequestObj('resetFacilityUser').post(data)
    .then((response) => {
      if(!Number(response?.data?.error)){
        notification.success({
          message: response?.data?.message,
          duration: 2,
          key: 1,
          style: { color: "green" },
          placement: 'bottomLeft',
        })
        onClose();
      }else{
        notification.error({
          message: response?.data?.message,
          duration: 2,
          key: 1,
          style: { color: "red" },
          placement: 'bottomLeft',
        })
      }
    })
    .catch((e) => {
      notification.error({
        message: e?.message || e?.msg,
        duration: 2,
        key: 1,
        style: { color: "red" },
        placement: 'bottomLeft',
      })
    })
    .finally(() => setLoadingReset(false))
    // console.log(data)
  } 

  const onClose = () => {
    showPasswordResetForm(!!!passwordResetForm); 
    setTargetUser(null)
  }

  return (
    <>
      {!user || ![4].includes(Number(user.role)) ? (
        <Empty />
      ) : (
        <>
          <Row style={{ width: "100%", height: "calc(100vh - 63.78px)" }}>
            {/* <Col span={3} style={{ height: "100%" }}> */}
              {/* <Menu
                mode="vertical"
                items={[
                  {
                    label: "Users",
                    key: "users",
                    icon: <UserOutlined />,
                  },
                ]}
                style={{ width: "100%", height: "100%" }}
              />
            </Col> */}
            <Col span={24} style={{ height: "100%", overflowY: "auto" }}>
              <Row
                style={{
                  width: "100%",
                  maxHeight: "calc(100vh - 200px)",
                  overflowY: "auto",
                }}
                justify="center"
              >
                {
                  loading? <Spin style={{fontSize: 100}}/>:
                  <Col span={20}>
                    <Divider orientation="left" orientationMargin="0">
                      <Typography.Title level={5}>Current Users</Typography.Title>
                    </Divider>
                    {facilityUsers?.length ? (
                      <>
                        {facilityUsers?.map((u) => (
                          <>
                            <Row style={{ width: "100%", padding: '10px 0', borderBottom: '1px dashed black'}} align={'middle'} justify={'space-between'}>
                              <Col span={5}>
                                <Row>
                                  <Col span={24} style={{fontWeight: 'bolder'}}>Name:</Col>
                                  <Col span={24}>
                                    {u.facility_users_full_name}
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={5}>
                                <Row>
                                  <Col span={24} style={{fontWeight: 'bolder'}}>Email:</Col>
                                  <Col span={24}>{u.facility_users_email}</Col>
                                </Row>
                              </Col>
                              <Col span={4}>
                                <Row>
                                  <Col span={24} style={{fontWeight: 'bolder'}}>Phone:</Col>
                                  <Col span={24}>{u.facility_users_phone}</Col>
                                </Row>
                              </Col>
                              <Col span={3}>
                                <Row>
                                  <Col span={24} style={{fontWeight: 'bolder'}}>Role:</Col>
                                  <Col span={24} title={u?.role?.facility_roles_description} style={{cursor: 'pointer'}}>{u?.role?.facility_roles_name}</Col>
                                </Row>
                              </Col>
                              <Col span={7}>
                                <Row align={'middle'} justify={'space-between'}>
                                  {/* <Col span={10}>Status:</Col> */}
                                  <Col span={12}>
                                  {[0, 4].includes(Number(user.role)) &&
                                  Number(u.facility_users_role) !== 4 ? (
                                    <Col span={24}>
                                      <Switch
                                        defaultChecked={Number(
                                          u.facility_users_status,
                                        )}
                                        onChange={(e) =>
                                          updateSuerState(e, u.facility_users_id)
                                        }
                                        disabled={loading}
                                      />
                                    </Col>
                                  ) : null}
                                    {!Number(u.facility_users_status)? (
                                      <>
                                        <span style={{ color: "grey" }}>
                                          Inactive
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span style={{ color: "green" }}>
                                          Active
                                        </span>
                                      </>
                                    )}
                                  </Col>
                                  <Col span={12}>
                                    {Number(u.facility_users_status)? <Button onClick={() => handlePaswordResetForm(u)} style={{ color: "red" }} disabled={targetUser}>Reset Password</Button> : null}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <Empty
                          description={
                            <span>There are no Users in this category</span>
                          }
                          style={{ padding: 50, width: "100%" }}
                        />
                      </>
                    )}
                  </Col>
                }
              </Row>
              <Row style={{ width: "100%" }} justify="center">
                <Col span={20}>
                  <Divider />
                  <FormDisabledDemo callback={() => loadUsers()} active={loading}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      <PasswordResetModal visible={passwordResetForm} onClose={onClose} handleOk={handlePasswordReset} user={targetUser} loading = {loadingReset}/>
    </>
  );
};

const FormDisabledDemo = ({ callback, active }) => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({});
  const [visible, setVisible] = useState(false);
  const [loading, setloading] = useState(false);

  const showForm = () => {
    setVisible(true);
  };

  const hideForm = () => {
    setVisible(false);
  };

  const submit = async () => {
    setloading(true);

    if (Object.keys(formData).length < 3) {
      notification.error({
        message: "Missing Field",
        description: "make sure all the fields were appropriately filled",
        duration: 2,
        key: 1,
      });
      return;
    }

    message.loading({ content: "Loading..." });

    let reqData = await lib.createUser(formData);

    if (!reqData) return;

    if (reqData?.data?.error) {
      notification.error({
        message: reqData.data.message,
        duration: 2,
        key: 1,
      });
    } else {
      notification.success({
        message: reqData.data.message,
        duration: 2,
        key: 1,
      });
      setFormData({});
      hideForm();
      setTimeout(() => {
        callback();
      }, 500);
    }
    setloading(false);
  };

  return (
    <>
      <Button type="primary" onClick={showForm} disabled={active}>
        Add User
      </Button>
      <Modal
        title="User Creation Form"
        open={visible}
        destroyOnClose
        cancelText="Close"
        style={{ marginTop: -100 }}
        footer={[
          <Row justify="space-between" align="bottom">
            <Col>
              <Button
                key="close"
                type="primary"
                danger
                onClick={hideForm}
                style={{ color: "white", fontSize: "1em" }}
              >
                {" "}
                Close{" "}
              </Button>
            </Col>
            <Col>
              <Button
                key="back"
                type="peimary"
                onClick={submit}
                style={{ fontSize: "1em" }}
                disabled={loading}
              >
                {" "}
                Create User{" "}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <div>
          <Row span={[3, 12]}>
            <Col span={5}>Full Name</Col>
            <Col span={19}>
              <Input
                value={formData["facility_users_full_name"]}
                onChange={(e) =>
                  setFormData((d) => ({
                    ...d,
                    facility_users_full_name: e.target.value,
                    facility_id: user?.facility?.facility_id,
                  }))
                }
              />
            </Col>
          </Row>
          <Row span={[3, 12]}>
            <Col span={5}>Email</Col>
            <Col span={19}>
              <Input
                value={formData["facility_users_email"]}
                type={"email"}
                onChange={(e) =>
                  setFormData((d) => ({
                    ...d,
                    facility_users_email: e.target.value,
                  }))
                }
              />
            </Col>
          </Row>
          <Row span={[3, 12]}>
            <Col span={5}>Phone</Col>
            <Col span={19}>
              <Input
                value={formData["facility_users_phone"]}
                type={"tel"}
                onChange={(e) =>
                  setFormData((d) => ({
                    ...d,
                    facility_users_phone: e.target.value,
                  }))
                }
              />
            </Col>
          </Row>
          <Row span={[3, 12]}>
            <Col span={5}>Password</Col>
            <Col span={19}>
              <Input.Password
                value={formData["facility_users_password"]}
                onChange={(e) =>
                  setFormData((d) => ({
                    ...d,
                    facility_users_password: e.target.value,
                  }))
                }
              />
            </Col>
          </Row>
          <Row span={[3, 12]}>
            <Col span={5}>Role</Col>
            <Col span={19}>
              <Select
                value={formData["facility_users_role"]}
                onChange={(e) =>
                  setFormData((d) => ({ ...d, facility_users_role: e }))
                }
                style={{ width: "100%" }}
              >
                <Select.Option value="1">Front Desk</Select.Option>
                <Select.Option value="2">Claims Officer</Select.Option>
                <Select.Option value="3">Admin</Select.Option>
              </Select>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

const ClaimsHook = () => {
  const { set, user } = useAuth();
  const dispatcher = useDispatch();
  const navigator = useNavigate();

  useEffect(() => {
    if (user) {
      if (![0, 4].includes(Number(user.role))) {
        notification.error({
          message: "Invalid Page",
        });
        Helpers.logout(set, dispatcher);
        message.loading({
          content: "Terminating Current Session",
          duration: 2,
        });
        navigator("/login");
      }
    } else {
      navigator("/login");
    }
  });

  return (
    <>
      <>{<ClaimsRequestHook />}</>
    </>
  );
};

// PasswordResetModal.js

const PasswordResetModal = ({ visible, onClose, handleOk, user, loading }) => {
  const [form] = Form.useForm();

  const checkPass = () => {
    form.validateFields()
      .then(values => {
        // console.log('New Password:', values.newPassword);
        // Handle the password reset logic here
        // onClose();
        handleOk(values)
      })
      .catch(info => {
        notification.error({
          message: info.message,
          duration: 2,
          key: 1,
          style: { color: "red" },
          placement: 'bottomLeft',
        })
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title="Reset Password"
      visible={visible}
      onOk={checkPass}
      onCancel={onClose}
      confirmLoading={loading}
      destroyOnClose
    >
      <Row style={{fontWeight: 'bolder'}}>
        <Col span={4}>
          <p>
            User: 
          </p>
        </Col>
        <Col span={20}>
          <p>
            {user?.facility_users_full_name}
          </p>
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        name="password_reset_form"
      >
        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            { required: true, message: 'Please enter your new password!' },
            { min: 6, message: 'Password must be at least 6 characters!' }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            { required: true, message: 'Please confirm your new password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};


export default ClaimsHook;
