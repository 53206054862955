import React, { useState } from 'react';
import { Popconfirm, Button } from 'antd';
import {
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
    CheckCircleOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';

const PopConfirm = ({
    title,
    onConfirm,
    onCancel,
    confirmText = 'Yes',
    cancelText = 'No',
    okButtonProps = {},
    cancelButtonProps = {},
    triggerElement,
    triggerButtonText,
    triggerButtonProps = {},
    icon,
    placement = 'top',
    confirmType = 'default', // default, delete, confirm, warning, success, info
    onConfirmFeedback, // Callback after confirmation or cancellation
    customFooter, // Custom footer content
    locale = 'en', // Localization support
    translations = {}, // Dynamic translations
    theme = 'default', // Custom theme
    debounceTime = 300, // Debounce time for preventing rapid clicks
}) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [lastClickTime, setLastClickTime] = useState(0);

    // Translation support
    const localizedConfirmText = translations[locale]?.confirmText || confirmText;
    const localizedCancelText = translations[locale]?.cancelText || cancelText;
    const localizedTitle = translations[locale]?.title || title;

    // Determine the default icon based on the confirmation type
    const getDefaultIcon = () => {
        switch (confirmType) {
            case 'delete':
                return <DeleteOutlined style={{ color: 'red' }} />;
            case 'confirm':
                return <CheckCircleOutlined style={{ color: 'green' }} />;
            case 'warning':
                return <ExclamationCircleOutlined style={{ color: 'orange' }} />;
            case 'success':
                return <CheckCircleOutlined style={{ color: 'green' }} />;
            case 'info':
                return <InfoCircleOutlined style={{ color: 'blue' }} />;
            default:
                return <QuestionCircleOutlined />;
        }
    };

    // Handle confirmation with loading state and debounce
    const handleConfirm = async () => {
        const now = Date.now();
        if (now - lastClickTime < debounceTime) {
            return; // Prevent rapid clicks
        }
        setLastClickTime(now);

        setConfirmLoading(true);
        try {
            await onConfirm();
            if (onConfirmFeedback) onConfirmFeedback('confirmed');
        } catch (error) {
            console.error('Error during confirmation:', error);
        } finally {
            setConfirmLoading(false);
        }
    };

    // Handle cancellation with optional feedback
    const handleCancel = () => {
        if (onCancel) onCancel();
        if (onConfirmFeedback) onConfirmFeedback('cancelled');
    };

    return (
        <Popconfirm
            title={typeof localizedTitle === 'string' ? localizedTitle : <div>{localizedTitle}</div>}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            okText={localizedConfirmText}
            cancelText={localizedCancelText}
            okButtonProps={{ ...okButtonProps, loading: confirmLoading, className: theme }}
            cancelButtonProps={{ ...cancelButtonProps, className: theme }}
            icon={icon || getDefaultIcon()}
            placement={placement}
        >
            {triggerElement || (
                <Button {...triggerButtonProps} aria-label={triggerButtonText}>
                    {triggerButtonText}
                </Button>
            )}
            {customFooter && <div className="custom-footer">{customFooter}</div>}
        </Popconfirm>
    );
};

export default PopConfirm;
